import React from "react";
import "../../Stylesheets/Marquee.css";
import Tooltip from "@mui/material/Tooltip";

function Marquee(props) {
  return (
    <>
      <div className="slider m-auto overflow-hidden position-relative w-100" >
        <div className="slide-track">
          {props.imgs &&
            props.imgs.map((obj, i) => {
              return (
                <div id="box" role="group" className="position-relative p-2 " key={i}>
                  <Tooltip title={obj.text} followCursor={true}>
                    <div className="slides" >
                      <img src={obj.img} className="rounded-4" alt="..." style={{height:"200px"}}/>
                    </div>
                  </Tooltip>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default Marquee;
