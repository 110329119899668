// import React, { useContext, useEffect, useState, useRef } from "react";
import React from "react";
// import twitter from "../Images/People/twitter.webp";
// import github from "../Images/People/github.webp";
// import linkedin from "../Images/People/linkedin.webp";
// import placeholder from "../Images/People/placeholder.webp";
// import people_background from "../Images/About_Us/about-background.webp";
import BackToTop from "../Components/Common/BackToTop";
import Navbar from "../Components/Common/Navbar";
import faculty1 from "../Images/People/Team Members/faculty1.webp";
import faculty2 from "../Images/People/Team Members/faculty2.webp";
import faculty3 from "../Images/People/Team Members/faculty3.webp";
import faculty4 from "../Images/People/Team Members/faculty4.webp";
import faculty5 from "../Images/People/Team Members/faculty5.webp";
import Dikshit from "../Images/People/Team Members/Dikshit.webp";
import Nikhil from "../Images/People/Team Members/Nikhil.webp";
import Nitish from "../Images/People/Team Members/Nitish.webp";
import Sampada from "../Images/People/Team Members/Sampada.webp";
import Chaitra from "../Images/People/Team Members/Chaitra.webp";
// import blank from "../Images/People/Team Members/blank.webp";
import img1 from "../Images/Alumni/PlaceHolder/Abhijeet Ganapule.webp";
import img2 from "../Images/Alumni/PlaceHolder/Abhishek Sangam.webp";
import img3 from "../Images/Alumni/PlaceHolder/Akshay Gunari.webp";
import img4 from "../Images/Alumni/PlaceHolder/Channabasappa Konin.webp";
import img5 from "../Images/Alumni/PlaceHolder/Samanvitha Karanth.webp";
import img6 from "../Images/Alumni/PlaceHolder/Shanthika Naik.webp";
import img7 from "../Images/Alumni/PlaceHolder/Soumya Jahagirdar.webp";
import img8 from "../Images/Alumni/PlaceHolder/Sujaykumar Kulkarni.webp";
import img9 from "../Images/Alumni/PlaceHolder/T Santoshkumar.webp";
import "../Stylesheets/People.css";
import topbg from "../Images/People/top_background1.webp";
import Footer from "../Pages/Footer.js";
import "../index.css";
import Hero from "../Components/Common/Hero.js";
function Team() {
  return (
    <>
      <div className=" main min-vh-100">
        <BackToTop />
        <Navbar />
        <br />
        <br />
        <br />
        <Hero title="Team" img={topbg} 
        creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
        creator="Pawel Czerwinski"
        imageLink="https://unsplash.com/photos/a-computer-generated-image-of-a-flower-on-a-blue-background-e6uw1b_ZeRQ?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
        source="Unsplash"/>
        <br />
        <div className="container">
          <div className="mb-4 mt-4">
            <p className="fw-bold text-center heading-font-size">
              Team Members
            </p>
          </div>
          <br />
          <div className=" row row-cols-lg-5 row-cols-md-5 row-cols-1 d-flex row text-center align-items-start mb-4 gy-4">
            <div className="col-6 col-md">
              <img
                src={faculty5}
                className="rounded-circle img-fluid"
                alt=""
                style={{ width: "90%" }}
              />
              <p className="pt-4 mb-0 fw-semibold teams-font-size" style={{color:"#0870d8"}}>Dr. Uma Mudenagudi</p>
              <p className="pt-1 b-0 text-muted">Director</p>
            </div>
            {/* <div className="col-6 col-md p-0 image-container">
              <img
                src={faculty5}
                className="img-fluid"
                alt="Chaitra S"
                style={{ width: "100%", height: "auto" }}
              />
              <div className="overlay">
                <div className="text text-center ">
                  <b> Dr. Uma Mudenagudi</b>
                  <br />
                  Director
                  <br />
                  CEVI
                </div>
              </div>
            </div> */}
            <div className="col-6 col-md">
              <img
                src={faculty1}
                className="rounded-circle img-fluid"
                alt=""
                style={{ width: "90%" }}
              />
              <p className="pt-4 mb-0 fw-semibold teams-font-size" style={{color:"#0870d8"}}>Dr. Sujata C</p>
              <p className="pt-1 b-0 text-muted">Research Faculty</p>
            </div>
            {/* <div className="col-6 col-md p-0 image-container">
              <img
                src={faculty1}
                className="img-fluid"
                alt="Chaitra S"
                style={{ width: "100%", height: "auto" }}
              />
              <div className="overlay">
                <div className="text text-center ">
                  <b>Dr. Sujata C</b>
                  <br />
                  Research Faculty
                  <br />
                  CEVI
                </div>
              </div>
            </div> */}

            <div className="col-6 col-md">
              <img
                src={faculty4}
                className="rounded-circle img-fluid"
                alt=""
                style={{ width: "90%" }}
              />
              <p className="pt-4 mb-0 fw-semibold teams-font-size" style={{color:"#0870d8"}}>Dr. Padmashri Desai</p>
              <p className="pt-1 b-0 text-muted">Research Faculty</p>
            </div>
            {/* <div className="col-6 col-md p-0 image-container">
              <img
                src={faculty4}
                className="img-fluid"
                alt="Chaitra S"
                style={{ width: "100%", height: "auto" }}
              />
              <div className="overlay">
                <div className="text text-center ">
                  <b>Dr. Padmashri Desai</b>
                  <br />
                  Research Faculty
                  <br />
                  CEVI
                </div>
              </div>
            </div> */}
            <div className="col-6 col-md">
              <img
                src={faculty3}
                className="rounded-circle img-fluid"
                alt=""
                style={{ width: "90%" }}
              />
              <p className="pt-4 mb-0 fw-semibold teams-font-size" style={{color:"#0870d8"}}>Ramesh Ashok Tabib</p>
              <p className="pt-1 b-0 text-muted">Research Faculty</p>
            </div>
            {/* <div className="col-6 col-md p-0 image-container">
              <img
                src={faculty3}
                className="img-fluid"
                alt="Chaitra S"
                style={{ width: "100%", height: "auto" }}
              />
              <div className="overlay">
                <div className="text text-center ">
                  <b>Ramesh Ashok Tabib</b>
                  <br />
                  Research Faculty
                  <br />
                  CEVI
                </div>
              </div>
            </div> */}
            <div className="col-6 col-md">
              <img
                src={faculty2}
                className="rounded-circle img-fluid"
                alt=""
                style={{ width: "90%" }}
              />
              <p className="pt-4 mb-0 fw-semibold teams-font-size" style={{color:"#0870d8"}}>Kaushik Mallibhat</p>
              <p className="pt-1 b-0 text-muted">Research Faculty</p>
            </div>

            {/* <div className="col-6 col-md p-0 image-container">
              <img
                src={faculty2}
                className="img-fluid"
                alt="Chaitra S"
                style={{ width: "100%", height: "auto" }}
              />
              <div className="overlay">
                <div className="text text-center ">
                  <b>Kaushik Mallibhat</b>
                  <br />
                  Research Faculty
                  <br />
                  CEVI
                </div>
              </div>
            </div> */}

            <div className="col-6 col-md">
              {/* <a href="">
                <img
                  src={faculty5}
                  className="rounded-circle img-fluid"
                  alt=""
                  style={{ width: "90%" }}
                />
              </a>
              <p className="pt-4 mb-0 fw-semibold">Dr. Uma Mudenagudi</p>
              <p className="pt-1 b-0 text-muted mb-0">Director</p>
              <p className="pt-1 b-0 text-muted">CEVI</p> */}
            </div>
            {/* <div className="col-6 col-md p-0 image-container d-none d-md-block">
              <img
                src={blank}
                alt="blank"
                className="img-fluid"
                style={{ width: "100%", height: "auto" }}
              />
            </div> */}
            <div className="col-6 col-md">
              <img
                src={Chaitra}
                className="rounded-circle img-fluid"
                alt=""
                style={{ width: "90%" }}
              />
              <p className="pt-4 mb-0 fw-semibold teams-font-size" style={{color:"#0870d8"}}>Chaitra Desai</p>
              <p className="pt-1 b-0 text-muted">Research Scholar</p>
            </div>

            {/* <div className="col-6 col-md p-0 image-container">
              <img
                src={Chaitra}
                className="img-fluid"
                alt="Chaitra S"
                style={{ width: "100%", height: "auto" }}
              />
              <div className="overlay">
                <div className="text text-center ">
                  <b>Chaitra Desai</b>
                  <br />
                  Research Scholar
                  <br />
                  CEVI
                </div>
              </div>
            </div> */}

            <div className="col-6 col-md">
              <img
                src={Nikhil}
                className="rounded-circle img-fluid"
                alt=""
                style={{ width: "90%" }}
              />
              <p className="pt-4 mb-0 fw-semibold teams-font-size" style={{color:"#0870d8"}}>Nikhil Akalwadi</p>
              <p className="pt-1 b-0 text-muted">Research Assistant</p>
            </div>
            {/* <div className="col-6 col-md p-0 image-container">
              <img
                src={Nikhil}
                className="img-fluid"
                alt="Chaitra S"
                style={{ width: "100%", height: "auto" }}
              />
              <div className="overlay">
                <div className="text text-center ">
                  <b>Nikhil Akalwadi</b>
                  <br />
                  Research Assistant
                  <br />
                  CEVI
                </div>
              </div>
            </div> */}
            <div className="col-6 col-md">
              <img
                src={Sampada}
                className="rounded-circle img-fluid"
                alt=""
                style={{ width: "90%" }}
              />
              <p className="pt-4 mb-0 fw-semibold teams-font-size" style={{color:"#0870d8"}}>Sampada Malagi</p>
              <p className="pt-1 b-0 text-muted">Research Assistant</p>
            </div>

            {/* <div className="col-6 col-md p-0 image-container">
              <img
                src={Sampada}
                className="img-fluid"
                alt="Chaitra S"
                style={{ width: "100%", height: "auto" }}
              />
              <div className="overlay">
                <div className="text text-center ">
                  <b>Sampada Malagi</b>
                  <br />
                  Research Assistant
                  <br />
                  CEVI
                </div>
              </div>
            </div> */}

            <div className="col-6 col-md">
              {/* <a href="">
                <img
                  src={faculty5}
                  className="rounded-circle img-fluid"
                  alt=""
                  style={{ width: "90%" }}
                />
              </a>
              <p className="pt-4 mb-0 fw-bold">Dr. Uma Mudenagudi</p>
              <p className="pt-1 b-0 text-muted mb-0">Director</p>
              <p className="pt-1 b-0 text-muted">CEVI</p> */}
            </div>
            {/* <div className="col-6 col-md p-0 image-container d-none d-md-block">
              <img
                src={blank}
                alt="blank"
                className="img-fluid"
                style={{ width: "100%", height: "auto" }}
              />
            </div> */}
          </div>

          <br />
          <br />

          <div className="text-center fw-bold mb-4 mt-2">
            <p className="heading-font-size">Alumni</p>
          </div>
          <br />
          {/* <div className="justify-content-center"></div> */}
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-bs-ride="carousel"
            data-bs-touch="false"
          >
            <div className="carousel-inner text-center mb-5">
              <div className="carousel-item active" data-bs-interval="4000">
                <div className="row ml-2 gy-3">
                  <div className="col-md-1"></div>
                  <div className="col-6 col-md ">
                    <img
                      src={Dikshit}
                      className=" img-fluid rounded-4"
                      alt=""
                      style={{ width: "95%" }}
                    />
                    <h5 className="pt-4 mb-0 fw-semibold alumni-font-size">Dikshit Hegde</h5>
                    {/* <p className="pt-1 b-0 text-muted d-none d-md-block">Hardware design engineer, Germany </p> */}
                    <p className="pt-1 mb-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      Research Assistant,
                    </p>
                    <p className="pt-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      IIT Jodhpur{" "}
                    </p>
                  </div>
                  <div className="col-6 col-md">
                    <img
                      src={img5}
                      className=" img-fluid rounded-4"
                      alt=""
                      style={{ width: "95%" }}
                    />
                    <h5 className="pt-4 mb-0 fw-semibold alumni-font-size">Samanvitha Karanth</h5>
                    <p className="pt-1 mb-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      Founder Engineer MTS,
                    </p>
                    <p className="pt-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      Aivu Pvt. Ltd
                    </p>
                  </div>
                  <div className="col-6 col-md ">
                    <img
                      src={img1}
                      className=" img-fluid rounded-4"
                      alt=""
                      style={{ width: "95%" }}
                    />
                    <h5 className="pt-4 mb-0 fw-semibold alumni-font-size">Abhijeet Ganapule</h5>
                    {/* <p className="pt-1 b-0 text-muted d-none d-md-block">Hardware design engineer, Germany </p> */}
                    <p className="pt-1 mb-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      Hardware Design Engineer,
                    </p>
                    <p className="pt-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      VALEO Telematik und Akustik GmbH, Germany{" "}
                    </p>
                  </div>
                  <div className="col-6 col-md">
                    <img
                      src={img2}
                      className=" img-fluid rounded-4"
                      alt=""
                      style={{ width: "95%" }}
                    />
                    <h5 className="pt-4 mb-0 fw-semibold alumni-font-size">Abhishek Sangam</h5>
                    <p className="pt-1 mb-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      Technical Specialist (CG/XR),
                    </p>
                    <p className="pt-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      Sony
                    </p>
                  </div>

                  <div className="col-md-1"></div>
                </div>
              </div>
              <div className="carousel-item" data-bs-interval="4000">
                <div className="row ml-2 gy-3">
                  <div className="col-md-1"></div>
                  <div className="col-6 col-md">
                    <img
                      src={img3}
                      className=" img-fluid rounded-4"
                      alt=""
                      style={{ width: "95%" }}
                    />
                    <h5 className="pt-4 mb-0 fw-semibold alumni-font-size">Akshaykumar Gunari</h5>
                    <p className="pt-1 mb-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      Software System Designer - 2,
                    </p>
                    <p className="pt-0 b-0 text-muted d-none d-md-block alumni-content-font-size">AMD</p>
                  </div>
                  <div className="col-6 col-md">
                    <img
                      src={img4}
                      className=" img-fluid rounded-4"
                      alt=""
                      style={{ width: "95%" }}
                    />
                    <h5 className="pt-4 mb-0 fw-semibold alumni-font-size">Channabasappa Konin</h5>
                    <p className="pt-1 mb-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      Lead Engineer,
                    </p>
                    <p className="pt-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      BYJU's
                    </p>
                  </div>
                  <div className="col-6 col-md">
                    <img
                      src={img9}
                      className=" img-fluid rounded-4"
                      alt=""
                      style={{ width: "95%" }}
                    />
                    <h5 className="pt-4 mb-0 fw-semibold alumni-font-size">T Santoshkumar</h5>
                    <p className="pt-1 mb-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      Senior Software Engineer,
                    </p>
                    <p className="pt-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      Multicoreware
                    </p>
                  </div>
                  <div className="col-6 col-md">
                    <img
                      src={img6}
                      className=" img-fluid rounded-4"
                      alt=""
                      style={{ width: "95%" }}
                    />
                    <h5 className="pt-4 mb-0 fw-semibold alumni-font-size">Shanthika Naik</h5>
                    <p className="pt-1 mb-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      Master's student,
                    </p>
                    <p className="pt-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      IIIT Hyderabad
                    </p>
                  </div>

                  <div className="col-md-1"></div>
                </div>
              </div>
              <div className="carousel-item" data-bs-interval="4000">
                <div className="row ml-2 gy-3">
                  <div className="col-md-1"></div>
                  <div className="col-6 col-md">
                    <img
                      src={img7}
                      className=" img-fluid rounded-4"
                      alt=""
                      style={{ width: "95%" }}
                    />
                    <h5 className="pt-4 mb-0 fw-semibold alumni-font-size">
                      Soumya Shamarao Jahagirdar
                    </h5>
                    <p className="pt-1 mb-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      Master's student,
                    </p>
                    <p className="pt-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      IIIT Hyderabad
                    </p>
                  </div>
                  <div className="col-6 col-md ">
                    <img
                      src={Nitish}
                      className=" img-fluid rounded-4"
                      alt=""
                      style={{ width: "95%" }}
                    />
                    <h5 className="pt-4 mb-0 fw-semibold alumni-font-size">Nitishkumar Upasi</h5>
                    {/* <p className="pt-1 b-0 text-muted d-none d-md-block">Hardware design engineer, Germany </p> */}
                    <p className="pt-1 b-0 text-muted d-none d-md-block alumni-content-font-size"> </p>
                  </div>
                  <div className="col-6 col-md">
                    <img
                      src={img8}
                      className=" img-fluid rounded-4"
                      alt=""
                      style={{ width: "95%" }}
                    />
                    <h5 className="pt-4 mb-0 fw-semibold alumni-font-size">Sujaykumar Kulkarni</h5>
                    <p className="pt-1 mb-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      Software Developer,
                    </p>
                    <p className="pt-0 b-0 text-muted d-none d-md-block alumni-content-font-size">
                      MathWorks
                    </p>
                  </div>

                  {/* Blank for extra people */}
                  {/* <div className="col-6 col-md"></div> */}
                  <div className="col-6 col-md"></div>
                  <div className="col-md-1"></div>
                </div>
              </div>
            </div>

            <button
              style={{ width: "50px", height: "50px", top: "45%" }}
              className="carousel-control-prev position-absolute  start-0 translate-middle-y "
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                className="bi bi-arrow-left-short"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                />
              </svg>
              <span className="visually-hidden">Previous</span>
            </button>

            <button
              style={{ width: "50px", height: "50px", top: "45%" }}
              className="carousel-control-next position-absolute  end-0 translate-middle-y"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                className="bi bi-arrow-right-short"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                />
              </svg>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        {/* {/* <br /> */}
        <br />
        <br />
        <Footer />
      </div>
    </>
  );
}

export default Team;
