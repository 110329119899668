import React from "react";
import Navbar from "../Components/Common/Navbar";
// import sponsored1 from "../Images/Sponsored_Project/AICTE.webp";
// import sponsored2 from "../Images/Sponsored_Project/hampi.webp";
// import Cards_main from "../Components/Research/Cards_main";
// import sponsored3 from "../Images/Sponsored_Project/Histopathology.webp";
// import dp from "../Images/Sponsored_Project/underwater.webp";
// import multi from "../Images/Research/multimodal_gif.webp";
// import ar from "../Images/Research/AR.webp";
// import research_background from "../Images/About_Us/about-background.webp";
import topbg from "../Images/About_Us/Bannera.webp";
import Footer from "../Pages/Footer.js";
import { Link } from "react-router-dom";
import image4 from "../Images/Sponsored_Project/AICTE.webp";
import image2 from "../Images/Sponsored_Project/underwater.webp";
import image3 from "../Images/Sponsored_Project/Histopathology.webp";
import image1 from "../Images/Sponsored_Project/hampi.webp";
import Hero from "../Components/Common/Hero.js";
import Breadcrumbs from "../Components/Common/Breadcrumbs";

function SponsoredResearch() {
  return (
    <div className="min-vh-100">
      <Navbar />
      <br />
      <br />
      <br />

      <Hero
        title="Sponsored Research Projects"
        img={topbg}
        creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
        creator="Pawel Czerwinski"
        imageLink="https://unsplash.com/photos/an-abstract-pink-background-with-wavy-lines-bloGOumd0Z4?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
        source="Unsplash"
      />

      <br />

      <div className="research-content1">
        <div className="container mt-3">
          <Breadcrumbs />
          <div className="row d-flex ">
            <div className="col-sm-6 pt-3">
              <h3 className="heading-font-size fw-bold">Ongoing Projects</h3>
            </div>
          </div>

          <div className="row justify-content-center my-5">
            <div className="col-12 col-sm-6 col-md-3 mb-3 justify-content-center">
              <Link
                to="/projects/sponsored/ihds"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <img
                  src={image1}
                  className="img-fluid w-100 rounded-4"
                  alt="DST IHDS-Crowdsourcing"
                />
                <h6 className="text-break text-center pt-2 sub-heading-font-size fw-semibold">
                  DST IHDS-Crowdsourcing <br /> 2019-2024
                </h6>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-3 justify-content-center mb-3">
              <Link
                to="/projects/sponsored/dst-dp"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <img
                  src={image2}
                  className="img-fluid w-100 rounded-4"
                  alt="DST Digital Poompuhar"
                />
                <h6 className="text-break text-center pt-2 sub-heading-font-size fw-semibold">
                  DST Digital Poompuhar <br /> 2019-2024
                </h6>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-3 mb-3">
              <Link
                to="/projects/sponsored/vaidya"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <img
                  src={image3}
                  className="img-fluid w-100 rounded-4"
                  alt="VGST V-Vaidya"
                />
                <h6 className="text-break text-center pt-2 sub-heading-font-size fw-semibold">
                  VGST V-Vaidya <br /> 2021-2023
                </h6>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-3 mb-3">
              <Link
                to="/projects/sponsored/aicte_rps"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <img
                  src={image4}
                  className="img-fluid w-100 rounded-4"
                  alt="AICTE-RPS Shape Representation"
                />
                <h6 className="text-break text-center pt-2 sub-heading-font-size fw-semibold">
                  AICTE-RPS Shape Representation <br /> 2019-2023
                </h6>
              </Link>
            </div>
          </div>

          <hr className="border-black" />
          <div className="text-break my-5 heading-font-size">
            <h3 className="heading-font-size fw-bold">Completed Projects</h3>
          </div>
          <div className="row">
            <div className="container">
              <ol className="list-number-size">
                <li>
                  <b className="sub-heading-font-size">
                    {" "}
                    DST: India Digital heritage ‘Acquisition, representation and
                    rendering of digital heritage sites’ in collaboration with
                    IIT Delhi’ (2010-2015){" "}
                  </b>
                  <br />
                  <p className="content-font-size">
                    The main objective is to create algorithms and techniques to
                    acquire three-dimensional digital replica of Indian digital
                    heritage sites. We have developed a framework for coarse to
                    fine 3D reconstruction using single-view reconstruction and,
                    fine reconstruction using depth sensor (Kinect) and
                    Multiview 3D reconstruction.
                  </p>
                </li>{" "}
                <hr className="my-4" />
                <li className="">
                  <b className="sub-heading-font-size">
                    DST: India Digital heritage ‘Gesture based virtual
                    walkthrough of digital heritage sites – Hampi’ (2011-2015)
                  </b>
                  <br />
                  <p className="content-font-size">
                    We have proposed a number of 3D point cloud representations
                    and processing methods for the We are also exploring how
                    missing and occluded areas as well as areas that produce
                    significant noise in the captured data are effectively
                    interpolated.
                  </p>
                </li>{" "}
                <hr className="my-4" />
                <li>
                  <b className="sub-heading-font-size">
                    IEEE EPIC scheme: Communicate ‘A Hand Speech Glove
                    (2010-2018)'
                  </b>
                  <br />
                  <p className="content-font-size">
                    Objective is to develop a human interface device that
                    converts the mechanism of hand sign language into
                    alphanumerical characters which would assist the user to
                    communicate via text display and speech. Awards: (i) Won
                    first prize at India, Innovation Initiative-i3 National fair
                    2012, held at IIT Delhi, 3 Dec 2012. Received a cash prize
                    of Rs. 25,000/ and represented India in the commonwealth
                    nations meet during Jan 2013. (iii) Infymakers Award, (iv)
                    IESA award.
                  </p>
                </li>{" "}
                <hr className="my-4" />
                <li className="">
                  <b className="sub-heading-font-size">
                    Naval Research Board (NRB): ‘Super-resolution and
                    retargeting in Images and Videos’ (2009-2012) in
                    collaboration with IIT Delhi and IIIT Hyderabad
                  </b>
                  <br />
                  <p className="content-font-size">
                    The aim is to generate a summary of the video by retaining
                    salient activities in a given time. We propose a method for
                    selection of salient activities using motion of feature
                    points as a key parameter, where the saliency of a frame
                    depends on total motion and specified time for
                    summarization. The motion information in a video is modelled
                    as a Gaussian mixture model (GMM), to estimate the key
                    motion frames in the video. The salient frames are detected
                    depending upon the motion strength of the keyframe and user
                    specified time, which contributes for the summarization
                    keeping the chronology of activities.
                  </p>
                </li>{" "}
                <hr className="my-4" />
                <li>
                  <b className="sub-heading-font-size">
                    DRDO, ADE- ‘Robust horizon detection for UAV’ (2013 - 2014)
                  </b>
                  <br />
                  <p className="content-font-size">
                    We have proposed number of horizon detection methods and
                    also method to select robust methods for a given scenario
                    for the robust horizon detection for UAVs.
                  </p>
                </li>{" "}
                <hr className="my-4" />
                <li className="">
                  <b className="sub-heading-font-size">
                    VTU: ‘Image Fusion and Quality metrics for images’
                    (2010-2014)
                  </b>
                  <br />
                  <p className="content-font-size">
                    The main objective is to develop framework which provides
                    better fusion results for different input images. We provide
                    a new quality to quantify the fused image.
                  </p>
                </li>
              </ol>
            </div>
          </div>
          <div className="row justify-content-center my-5">
            {/* <div className="w-auto">
                  <Button
                    title="View more"
                    id="view more"
                    className="ongoingProjectsButton"
                  />
                </div> */}
          </div>
        </div>
      </div>
      {/* <br /> */}
      <br />
      <Footer />
    </div>
  );
}

export default SponsoredResearch;
