import React from "react";
import "../../Stylesheets/Research_Cards.css";
import { Link } from "react-router-dom";

function Cards(props) {
  return (
    <>
      <div className="card h-100 border-0 rounded-4 r-card-body text-center">
        <img
          src={props.img}
          className=" card-img-top rounded-4 img-fluid r-card-image"
          alt="..."
        />
        <div className="card-body card-img-overlay justify-content-between r-card-title flex-column">
          <div className="card-title text-black">
            <Link
              to={props.link}
              className="sub-heading-font-size stretched-link text-black r-card-link"
            >
              {props.title}
            </Link>
          </div>
            {/* <a className="text-light text-decoration-none mb-2 attribution-font-size" href="https://www.flaticon.com/free-icons/instagram-logo" title="instagram logo icons">Instagram logo icons created by Freepik - Flaticon</a> */}
          {/* <p className="card-text mt-1 justify-content-end r-card-desc">
            {props.desc}
          </p> */}
        </div>
      </div>
    </>
  );
}

export default Cards;
