import React, { useContext, useEffect, useState } from "react";
import categoryContext from "../../Global varaibles/Category_filter/Category_context";

// List of common stop words to exclude
const stopWords = ['and', 'or', 'the', 'in', 'at', 'of', 'a', 'for', 'on', 'with'];

function ExcelDatabaseReader() {
  const context = useContext(categoryContext);
  const { category, setCategory } = context; // Destructure category and setCategory from context

  // State to store topics fetched from Excel
  const [topics, setTopics] = useState([]);

  // Function to generate initials from category names
  const generateInitials = (categoryName) => {
    if (!categoryName || typeof categoryName !== 'string') {
      return ''; // Return empty string if categoryName is invalid or not a string
    }

    // Stop processing the string after the first '('
    const nameBeforeParenthesis = categoryName.split('(')[0].trim();

    const words = nameBeforeParenthesis.split(' ');
    let initials = '';

    words.forEach(word => {
      // Check if word is valid (non-null, non-empty string)
      if (word && typeof word === 'string' && word.trim() !== '' && !stopWords.includes(word.toLowerCase())) {
        initials += word[0].toUpperCase(); // Add the first letter of each valid word
      }
    });

    return initials;
  };

  // Function to load data from the Excel API
  const loadExcel = async () => {
    const response = await fetch("https://cevi.co.in/publication_react.php");
  
    const json = await response.json();
    json.shift(); // Remove the first element (unset($sheetData[0]))
  
    let str = "";
    for (let i = 0; i < json.length; i++) {
      const t = json[i];
  
      // Check if t[9] exists and is not null
      if (t[0] === undefined || !t[0] || t[9] === "null" || t[9] === null) {
        break;
      }
      // Only concatenate if t[9] is a string
      if (typeof t[9] === 'string') {
        str = str + t[9].trim() + ","; // Trim topics before concatenation
      }
    }
  
    // Split, trim, filter out empty strings and nulls, then create a Set to remove duplicates
    const topics = [...new Set(str.split(",").map(topic => topic.trim()).filter(topic => topic && topic !== "null"))];
  
    setTopics(topics);
  };

  // Load topics when component mounts
  useEffect(() => {
    loadExcel();
  }, []);

  // Handle category selection from dropdown
  const handleClick = (value) => {
    setCategory(value); // Update context with the full category name
  };

  return (
    <>
      {/* Dropdown Button */}
      <div className="dropdown">
        <button
          className="btn btn-light w-100 border-black text-center dropdown-toggle fw-medium"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {category ? generateInitials(category) : "Category (All)"} {/* Display initials or "All" */}
        </button>

        {/* Dropdown Items */}
        <ul
          className="dropdown-menu overflow-y-auto"
          id="category-dropdown"
          style={{ maxHeight: "200px" }}
        >
          <li>
            <button
              className="dropdown-item"
              onClick={() => handleClick("")} // "All" option
              type="button"
            >
              All
            </button>
          </li>
          {topics.map((t, index) => {
            if (t !== "null" && t !== "" && t !== " ") {
              return (
                <li key={index}>
                  <button
                    onClick={() => handleClick(t)} // Set the full category name when clicked
                    className="dropdown-item"
                    type="button"
                  >
                    {t} {/* Show full name in dropdown item */}
                  </button>
                </li>
              );
            }
            return null;
          })}
        </ul>
      </div>
    </>
  );
}

export default ExcelDatabaseReader;
