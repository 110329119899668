import React, { useState } from "react";
import "../../Stylesheets/ReadMore.css"
 
const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="text">
            {isReadMore ? text.slice(0, 435) : text}
            <span
                onClick={toggleReadMore}
                className="read-or-hide"
                style={{ color: "#0c0cff" }}
            >
                {isReadMore ? "...read more" : " show less"}
            </span>
        </p>
    );
};

export default ReadMore;