import React from "react";
import Navbar from "../Components/Common/Navbar";
import d1 from "../Images/Dataset/version1.webp";
// import d2 from "../Images/Dataset/Rectangle12513.png";
// import d3 from "../Images/Dataset/Rectangle12514.png";
// import d4 from "../Images/Dataset/Rectangle12515.png";
// import Cards from "../Components/Research/Cards";
import "../Stylesheets/Research.css";
// import research_background from "../Images/About_Us/about-background.webp";
import topbg from "../Images/Dataset/topbg.webp";
import Footer from "../Pages/Footer.js";
import CardsMain from "../Components/Datasets/CardsMain.js";
import Hero from "../Components/Common/Hero.js";

// import Breadcrumbs from "../Components/Common/Breadcrumbs";

function Datasets() {
  return (
    <>
      <div className="min-vh-100" >
        <Navbar />
        <br />
        <br />
        <br />

        <Hero title="Datasets" img={topbg} 
        creatorLink="https://unsplash.com/@faded_gallery?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
        creator="Faded_Gallery"
        imageLink="https://unsplash.com/photos/a-black-background-with-a-blue-pattern-on-it-MWDDQZO-CIg?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
        source="Unsplash"/>

        <br />
        <br />
        <br />
        {/* <Breadcrumbs /> */}

        <div className="research-content">
          <div className="container">
            <div className="row gy-5">
              <div className="col-lg col-md-6 gx-md-4 gx-xxl-4 gx-0">
                <CardsMain
                  img={d1}
                  // iframe={
                  //   <iframe
                  //     src="https://my.spline.design/clonergridmotioncopy-3133ca9854ef1ed4aff5f1c854c7b93e/"
                  //     frameborder="0"
                  //     width="100%"
                  //     height="100%"
                  //   ></iframe>
                  // }
                  title="RSUIGM"
                  id="01"
                  // link="/dataset_page"
                  link="https://drive.google.com/drive/folders/1suy4UEUW1T3r39ajzcx8k_0pYvFxFnZ1?usp=share_link"
                  desc=""
                />
              </div>

              <div className="col-lg col-md-6 mb-5">
                {/* <CardsMain
                  img={d2}
                  // iframe={
                  //   <iframe
                  //     src="https://my.spline.design/draganddropsnapcopy-6e153bc203561a018c36a2cc113cba28/"
                  //     frameborder="0"
                  //     width="100%"
                  //     height="100%"
                  //   ></iframe>
                  // }
                  title="Coming soon.."
                  id="02"
                  // link="/research/3d"
                  desc=""
                /> */}
              </div>

              <div className="col-lg col-md-6 mb-5">
                {/* <CardsMain
                  img={d3}
                  // iframe={
                  //   <iframe
                  //     src="https://my.spline.design/purple3diconscopy-7b63e4e2044942a5b813f3bea65b95ac/"
                  //     frameborder="0"
                  //     width="100%"
                  //     height="100%"
                  //   ></iframe>
                  // }
                  title="Coming soon.."
                  id="03"
                  // link="/research/multimodal"
                  desc= ""
                /> */}
              </div>
              <div className="col-lg col-md-6 mb-5">
                {/* <CardsMain
                  img={d4}
                  // iframe={
                  //   <iframe
                  //     src="https://my.spline.design/purple3diconscopy-7b63e4e2044942a5b813f3bea65b95ac/"
                  //     frameborder="0"
                  //     width="100%"
                  //     height="100%"
                  //   ></iframe>
                  // }
                  title="Coming soon.."
                  id="04"
                  // link="/research/multimodal"
                  desc=""
                /> */}
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <Footer />
      </div>
    </>
  );
}

export default Datasets;
