import img1 from "../Images/DST-DP/dp.webp";
import React from "react";
import Navbar from "../Components/Common/Navbar";
import about_background from "../Images/About_Us/Bannera.webp";
import "../Stylesheets/Publications.css";
import BackToTop from "../Components/Common/BackToTop";
import Footer from "../Pages/Footer.js";
import Breadcrumbs from "../Components/Common/Breadcrumbs";
import "../index.css";
import Hero from "../Components/Common/Hero.js";
import PublicationsFetch from "../Components/Publications/PublicationsFetchFunction.js";

function DstDp() {
  return (
    <>
      <div className="min-vh-100">
        <BackToTop /> <Navbar />
        <br />
        <br />
        <br />
        <Hero
          title="DST Sponsored: Digital Poompuhar"
          subTitle=""
          img={about_background}
          showAnimationText="false"
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/an-abstract-pink-background-with-wavy-lines-bloGOumd0Z4?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />
        <br />
        <div className="container" style={{ paddingTop: "1em" }}>
          <Breadcrumbs />

          <div className="row">
            <div className="container" style={{ paddingBottom: "1em" }}>
              <span className="content-font-size">
                3D reconstruction and visualization of the archaeological site
                Poompuhar demands high-quality images. Capturing high-quality
                images in an underwater environment encompasses several
                challenges due to dynamically varying underwater properties,
                floating particles, and submerged deposits. Approaches for the
                estimation of high-quality images from corrupted images may be
                categorized as image enhancement or image restoration.
              </span>
              <ol className="list-number-siz">
                <li className="content-font-size">
                  Image enhancement methods aim to improve the quality of
                  underwater images by adjusting color, and luminance and
                  removing noise.
                </li>
                <li className="content-font-size">
                  Image restoration entails reversing the effects of distortion
                  caused by attenuation of light and back-scatter using image
                  formation models
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col col-md text-center">
              <img src={img1} className="img-fluid" alt="Reconstruction" />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="container">
              <span className="text-black sub-heading-font-size">
                Towards enhancement of underwater images, we have
              </span>
              <ol className="list-number-size">
                <li className="content-font-size">
                  Designed and developed a fusion-based enhancement technique
                  for the enhancement of underwater images
                </li>
                <li className="content-font-size">
                  Developed an adaptive cubic spline technique in CIE lab space
                  to enhance underwater images.
                </li>
                <li className="content-font-size">
                  Emerged with a learning-based style transfer technique by
                  encoding content and style images and apply domain transfer
                  methodology to enhance underwater images.{" "}
                </li>
                <li className="content-font-size">
                  Developed a UIENET: Underwater image enhancement network. This
                  network uses a Generative Adversarial Network to learn the
                  mapping from a degraded image to its corresponding ground
                  truth using an image-to-image translation mechanism.
                </li>
              </ol>
              <span className="text-black sub-heading-font-size">
                Towards restoration of underwater images, we have
              </span>
              <ol className="list-number-size">
                <li className="content-font-size">
                  Designed a classification framework to determine the
                  underlying water type and perform restoration accordingly.
                </li>
                <li className="content-font-size">
                  Generated synthetic underwater images with depth as a clue for
                  modeling classification framework.
                </li>
                <li className="content-font-size">
                  We trained the classification model with the help of synthetic
                  images and test on real underwater images.
                </li>
                <li className="content-font-size">
                  Developed a learning-based framework with wavelets in the
                  pooling layer to capture the finer details for the
                  encoder-decoder model.
                </li>
                <li className="content-font-size">
                  Designed a framework for realistic underwater image generation
                  using state-of-the-art image formation model towards training
                  conditional generative networks for performing restoration.
                </li>
                <li className="content-font-size">
                  Developed a framework for restoration of degraded underwater
                  images using attenuation coefficients as a clue.
                </li>
              </ol>
              <span className="text-black sub-heading-font-size">
                Towards 3D data processing, we have
              </span>
              <ol className="list-number-size">
                <li className="content-font-size">
                  Designed a learning-based hole detection and hole filling
                  algorithm towards refining the reconstructed 3D model.
                </li>
              </ol>
              <span className="text-black sub-heading-font-size">
                Towards rendering technique, in an attempt to restore the
                original appearance of the reconstructed 3D data, we have
              </span>
              <ol className="list-number-size">
                <li className="content-font-size">
                  Rendered synthetic underwater images using state-of-the-art
                  image formation model towards training deep learning
                  architectures.
                </li>
              </ol>
            </div>
          </div>
          {/* <div className="row">
              <div className="col-md-6">
                  <p>
                      
                  </p>
              </div>
              <div className="col-md-6">
                  <img src={img2} className="img-fluid" alt="Your Image"/>
              </div>
            
          </div> */}
        </div>
        {/* related publications code */}
        <br />
        <div className="heading-font-size fw-bold text-center my-4">
          Related Publications
        </div>
        <PublicationsFetch projectFilter="DP" />
        {/* related publications code */}
        <Footer />
      </div>
    </>
  );
}

export default DstDp;
