import React from "react";
import Navbar from "../Components/Common/Navbar";
// import image from "../Images/Projects/top_background.webp";
import image from "../Images/Research/topbg21.webp";
import img1 from "../Images/Home/2Ddata.webp";
import img2 from "../Images/Home/3dTeddy.webp";
import img3 from "../Images/Home/multimodal_gif.webp";
import img4 from "../Images/Research/AR_VR_XR.gif";
// import img5 from "../Images/Research/google-deepmind-8heReYC6Zt0-unsplash 1.webp";
// import img6 from "../Images/Research/yash-bindra-qzZgkZTDvM4-unsplash 1.webp";
import "../Stylesheets/Research.css";
// import Tooltip from "@mui/material/Tooltip";
// import research_background from "../Images/About_Us/about-background.webp";
import Footer from "../Pages/Footer.js";
import Cardsp from "../Components/Research/Cards_main";
// import Breadcrumbs from "../Components/Common/Breadcrumbs";
import Hero from "../Components/Common/Hero.js"

function Research_trial() {
  return (
    <>
      <div className="min-vh-100" style={{ backgroundColor: "#F6F4F2" }}>
        <Navbar />
        <br />
        <br />
        <br />

        <Hero
        title="Research"
        img={image}
        creatorLink="https://unsplash.com/@snapsbyclark?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
        creator="Clark Van Der Beken"
        imageLink="https://unsplash.com/photos/a-bunch-of-blue-and-pink-papers-stacked-on-top-of-each-other-xApC8DIiD54?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
        source="Unsplash"
        />

        <br />


        {/* <Breadcrumbs /> */}

        <div className="research-content">
        <div className="container">
        {/* AK: Replaced the below cards with the cards from home screen. The below div is newly added */}
        <div className="container mt-5">
          <div className="row gy-5">
            <div className="col-lg-4 col-md-6 gx-md-4 gx-xxl-5 gx-0">
              <Cardsp
                img={img1}
                title="2D Data Acquisition and Processing"
                id="01"
                link="/research/2d"
                desc=" Our research extensively harnesses the capabilities of deep
                  generative models, not only for image enhancement and
                  denoising but also for data augmentation."
              />
            </div>

            <div className="col-lg-4 col-md-6 gx-md-4 gx-xxl-5 gx-0">
              <Cardsp
                img={img2}
                title="3D Data Acquisition and Processing"
                id="02"
                link="/research/3d"
                desc="Our current research focus is on 3D data acquisition and processing is dedicated to enhancing machines' ability to perceive, analyze, and interact with the 3D world."
              />
            </div>

            <div className="col-lg-4 col-md-6 gx-md-4 gx-xxl-5 gx-0">
              <Cardsp
                img={img3}
                title="Multimodal Data Acquisition and Processing"
                id="03"
                // link="/research/multimodal"
                desc="This research aims to develop AI models that can simultaneously process and comprehend visual and textual information, enabling machines to glean richer insights."
              />
            </div>

            <div className="col-lg-4 col-md-6 gx-md-3 gx-xxl-5 gx-0">
              <Cardsp
                img={img4}
                title="Presentation via Augmented / Virtual / Extended Reality"
                id="04"
               
                // link="/research/multimodal"
                desc="Our research extensively harnesses the capabilities of deep
                  generative models, not only for image enhancement and
                  denoising but also for data augmentation."
              />
             
            </div>

            {/* <div className="col-lg-4 col-md-6 gx-md-3 gx-xxl-5 gx-0">
              <Cardsp
                img={img5}
                title="3D Data Acquisition and Processing"
                id="05"
                // link="/research/multimodal"
                desc="Our current research focus is on 3D data acquisition and
                  processing is dedicated to enhancing machines' ability to
                  perceive, analyze, and interact with the 3D world."
              />
            </div>

            <div className="col-lg-4 col-md-6 gx-md-3 gx-0">
              <Cardsp
                img={img6}
                title="Multi-modal Data Acquisition and Processing"
                id="06"
                // link="/research/multimodal"
                desc="This research aims to develop AI models that can
                  simultaneously process and comprehend visual and textual
                  information, enabling machines to glean richer insights."
              />
            </div> */}
          </div>
        </div>
      </div>
        </div>
        <br />
        <br />
        <br />
        <Footer />
      </div>
    </>
  );
}

export default Research_trial;
