import React from "react";
import Navbar from "../Components/Common/Navbar.js";
import head_image from "../Images/Research/steve-johnson-bNEga8u4kUs-unsplash 2.webp";
import repimage from "../Images/Dataset/Data.webp";
import Footer from "./Footer.js";
import Hero from "../Components/Common/Hero.js";
// import body_image from "../Images/Publications/research_publication_image.webp"

const DatasetPage = () => {
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <br />
      <br />
      <br />
      <Hero title="Dataset" img={head_image} />
      <br />
      <br />

      <div className="container">
        <div className="my-5 d-flex justify-content-center">
          <img
            src={repimage}
            className="img-fluid rounded-2 ratio-21x9 mb-5"
            alt=""
          />
        </div>
        <div className="text-break info ps-5 pe-5">
          <hr className="border border-black opacity-100  mx-xl-2 my-4" />
          <h2 className="heading-font-size fw-bold mb-4">Introduction</h2>
          <p className="mb-5 content-font-size">
            To be pioneers in visual intelligence by providing{" "}
            <b>
              <i className="content-font-size">
                innovative environment, capture capabilities, outstanding
                research experience, global recognition, and research excellence
              </i>
            </b>{" "}
            with an impact on the industry and society.
          </p>
          <p className="content-font-size mb-5">
            To be pioneers in visual intelligence by providing innovative
            environment, capture capabilities, outstanding research experience,
            global recognition, and research excellence with an impact on the
            industry and society.
          </p>
          <p className="content-font-size mb-5">
            To be pioneers in visual intelligence by providing innovative
            environment, capture capabilities, outstanding research experience,
            global recognition, and research excellence with an impact on the
            industry and society.
          </p>

          <h4
            className="sub-heading-font-size fw-semibold mt-4"
            style={{ color: "#9F9E9E" }}
          >
            Industry
          </h4>
          <p className="content-font-size">Automatic-Electric</p>
          <h4
            className="sub-heading-font-size fw-semibold"
            style={{ color: "#9F9E9E" }}
          >
            Platform
          </h4>
          <p className="content-font-size">
            web <br /> mobile
          </p>
          <h4
            className="sub-heading-font-size fw-semibold"
            style={{ color: "#9F9E9E" }}
          >
            Serviece
          </h4>
          <p className="mb-5 content-font-size">AR Development</p>

          <h2 className="fw-bold heading-font-size">Conclusion</h2>
          <p className="mb-4 sub-heading-font-size fw-semibold">
            Outcome & Results
          </p>
          <p className="content-font-size">
            To be pioneers in visual intelligence by providing innovative
            environment, capture capabilities, outstanding research experience,
            global recognition, and research excellence with an impact on the
            industry and society.
          </p>
          <div>
            Click here to{" "}
            <a
              href="https://drive.google.com/drive/folders/1suy4UEUW1T3r39ajzcx8k_0pYvFxFnZ1?usp=share_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              download
            </a>{" "}
            the dataset
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DatasetPage;
