import React from 'react';
import { Link, useLocation, useResolvedPath } from 'react-router-dom';
import "../../Stylesheets/Breadcrumbs.css";

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const pathNameMap = {
  '2d': '2D Data Acquisition and Processing',
  '3d': '3D Data Acquisition and Processing',
  'ihds':'DST IHDS-Crowdsourcing ',
  'dst-dp':'DST Digital Poompuhar',
  'aicte_rps':'AICTE-RPS Shape Representation',
  'vaidya':'VGST V-Vaidya',
  'Two_Dimensional_Page1' : '2D Data Acquisition / Capture Setup',
  'Two_Dimensional_Page2':  'Image Restoration and Enhancement',
  'Two_Dimensional_Page3': 'Learning Representation of 2D Data',
  'Two_Dimensional_Page4' : 'Filtering, Classification, and Categorization of 2D Data',
  'Two_Dimensional_Page5':  'Quality Analysis of Images',
  'Two_Dimensional_Page6': 'Detection, Localization, and Tracking',
  'Three_Dimensional_Page1': '3D Data Acquisition',
  'Three_Dimensional_Page2' : '3D Reconstruction and Recreation',
  'Three_Dimensional_Page3': 'Learning Representation of 3D Data',
  'Three_Dimensional_Page4': 'Refinement of 3D Data',
  'Three_Dimensional_Page5' : '3D Data Filtering, Classification, and Categorization',
  'Three_Dimensional_Page6': '3D Object Detection and Tracking',
  'sponsored': 'Sponsored Research Projects',
  'Consultancy': 'Consultancy Projects'

};

const mapPathToName = (path) => pathNameMap[path] || capitalizeFirstLetter(path.replace(/_/g, ' '));


  const Breadcrumbs = () => {
    const location = useLocation();
    const resolvedPath = useResolvedPath(location.pathname);
    const pathSegments = resolvedPath.pathname.split('/').filter(Boolean);
  
    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {pathSegments.map((segment, index) => (
            <li
              key={index}
              className={`breadcrumb-item ${index === pathSegments.length - 1 ? 'active' : ''}`}
            >
              {index === pathSegments.length - 1 ? (
                mapPathToName(segment)
              ) : (
                <Link to={`/${pathSegments.slice(0, index + 1).join('/')}`} className="breadcrumb-link ">
                  {mapPathToName(segment)}
                </Link>
              )}
            </li>
          ))}
        </ol>
      </nav>
    );
  };

export default Breadcrumbs;

  

