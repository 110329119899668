import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Router from "./Router/Router";
import Category from "./Global varaibles/Category_filter/Category";
import Year from "./Global varaibles/Year_filter/Year";
import PaperType from "./Global varaibles/PaperType_filter/PaperType";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <PaperType>
      <Year>
        <Category>
          <Router />
        </Category>
      </Year>
    </PaperType>
  </BrowserRouter>

  // <React.StrictMode>
  //   <PaperType>
  //     <Year>
  //       <Category>
  //         <BrowserRouter>
  //           <Router />
  //         </BrowserRouter>
  //       </Category>
  //     </Year>
  //   </PaperType>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
