import React from "react";
import Navbar from "../Components/Common/Navbar";
import about_background from "../Images/2D/Hero.webp";
import img1 from "../Images/3D/3D Data Object Detection and Tracking.webp";
import "../Stylesheets/Publications.css";
import BackToTop from "../Components/Common/BackToTop";
import Footer from "../Pages/Footer.js";

import Breadcrumbs from "../Components/Common/Breadcrumbs";
import Hero from "../Components/Common/Hero.js";

import ReadMore from "../Components/Common/ReadMore.js";
import PublicationsFetch from "../Components/Publications/PublicationsFetchFunction.js";

function ThreeDimensionalPage6() {
  return (
    <>
      <div className="min-vh-100" style={{ backgroundColor: "#F6F4F2" }}>
        <BackToTop />
        <Navbar />
        <br />
        <br />
        <br />

        <Hero
          title="3D Object Detection and Tracking"
          subTitle=""
          img={about_background}
          showAnimationText="false"
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/a-computer-generated-image-of-a-pink-and-purple-wave-7FqOISWr5V0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />

        <br />

        <div
          className="container"
          style={{ paddingBlock: "1em", marginTop: "1em" }}
        >
          <Breadcrumbs />
          <div className="row">
            <div className="container content-font-size">
              <ReadMore>
                Our initiatives revolve around the development of cutting-edge
                algorithms and models tailored for the precise detection and
                tracking of objects within three-dimensional spaces. These
                capabilities are pivotal in a multitude of applications,
                including autonomous vehicles, where accurate object detection
                and tracking are essential for safe navigation; robotics, where
                it enables machines to interact seamlessly with their
                surroundings; and augmented reality, where the alignment of
                virtual elements with the real world depends on precise
                tracking. By fine-tuning and enhancing these algorithms, we aim
                to empower these technologies with the ability to make sense of
                the 3D world, facilitating safer, more efficient, and more
                immersive experiences across various domains.
              </ReadMore>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col col-md text-center">
              <img src={img1} className="img-fluid" alt="..." />
            </div>
          </div>
        </div>
        {/* related publications code */}
        <br />
        <br />
        <div
          className="text-font-size fw-bold heading-font-size text-center"
          style={{
            paddingBlockEnd: "1em",
          }}
        >
          Related Publications
        </div>
        <PublicationsFetch researchFilter="3D Object Detection and Tracking" />
        {/* related publications code */}
        <Footer />
      </div>
    </>
  );
}

export default ThreeDimensionalPage6;
