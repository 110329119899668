import React from "react";
import Navbar from "../Components/Common/Navbar";
import about_background from "../Images/2D/Hero.webp";
import img1 from "../Images/3D/3D Data Classification.webp";
import "../Stylesheets/Publications.css";
import BackToTop from "../Components/Common/BackToTop";
import Footer from "../Pages/Footer.js";

import Breadcrumbs from "../Components/Common/Breadcrumbs";
import Hero from "../Components/Common/Hero.js";

import ReadMore from "../Components/Common/ReadMore.js";
import PublicationsFetch from "../Components/Publications/PublicationsFetchFunction.js";

function ThreeDimensionalPage5() {
  return (
    <>
      <div className="min-vh-100" style={{ backgroundColor: "#F6F4F2" }}>
        <BackToTop />
        <Navbar />
        <br />
        <br />
        <br />

        <Hero
          title="3D Data Filtering, Classification, and Categorization"
          subTitle=""
          img={about_background}
          showAnimationText="false"
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/a-computer-generated-image-of-a-pink-and-purple-wave-7FqOISWr5V0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />

        <br />

        <div
          className="container"
          style={{ paddingBlock: "1em", marginTop: "1em" }}
        >
          <Breadcrumbs />
          <div className="row">
            <div className="container content-font-size">
              <ReadMore>
                Our research endeavors are deeply rooted in the application of
                advanced filtering, classification, and categorization
                techniques to effectively organize and classify 3D data based on
                a myriad of object attributes, shapes, or other pertinent
                characteristics. This categorization process is fundamental for
                a range of critical tasks, including object recognition and
                scene understanding. By implementing these techniques, we
                empower systems to recognize and differentiate objects within 3D
                data, allowing for more informed decision-making processes.
                These capabilities have broad implications, spanning fields such
                as industrial automation, urban planning, and virtual reality,
                where the ability to interpret and categorize 3D data is
                instrumental in enhancing automation, analysis, and user
                experiences. Through our research, we aim to refine these
                techniques, making them more accurate and adaptable to diverse
                applications.
              </ReadMore>
            </div>
          </div>
          <div className="row">
            <div className="col col-md text-center">
              <img src={img1} className="img-fluid" alt="..." />
            </div>
          </div>
        </div>
        {/* related publications code */}
        <br />
        <br />
        <div
          className="text-center fw-bold heading-font-size"
          style={{
            paddingBlockEnd: "1em",
          }}
        >
          Related Publications
        </div>
        <PublicationsFetch researchFilter="3D Data Filtering Classification and Categorization" />
        {/* related publications code */}
        <Footer />
      </div>
    </>
  );
}

export default ThreeDimensionalPage5;
