import React from "react";
import Navbar from "../Components/Common/Navbar";
import about_background from "../Images/2D/Hero.webp";
import img1 from "../Images/2D/IQA.webp";
import "../Stylesheets/Publications.css";
import BackToTop from "../Components/Common/BackToTop";
import Footer from "../Pages/Footer.js";
import Breadcrumbs from "../Components/Common/Breadcrumbs";
import Hero from "../Components/Common/Hero.js";

import ReadMore from "../Components/Common/ReadMore.js";
import PublicationsFetch from "../Components/Publications/PublicationsFetchFunction.js";

function TwoDimensionalPage5() {
  return (
    <>
      <div className="min-vh-100" style={{ backgroundColor: "#F6F4F2" }}>
        <BackToTop />
        <Navbar />
        <br />
        <br />
        <br />

        <Hero
          title="Quality Analysis of Images"
          subTitle=""
          img={about_background}
          showAnimationText="false"
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/a-computer-generated-image-of-a-pink-and-purple-wave-7FqOISWr5V0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />

        <br />
        <div
          className="container"
          style={{ paddingBlock: "1em", marginTop: "1em" }}
        >
          <Breadcrumbs />
          <div className="row">
            <div className="container content-font-size">
              <ReadMore>
                A fundamental pillar of our research is the assurance of high
                quality and dependable acquired images. To achieve this, we
                invest significant efforts in the development of innovative
                methods and metrics specifically designed for assessing image
                quality. These metrics encompass critical factors such as
                sharpness, color fidelity, and noise levels. Through meticulous
                quality analysis, we not only quantify the excellence of our
                acquired images but also pinpoint potential issues within the
                image acquisition process. In this pursuit, we harness advanced
                tools and software, such as MATLAB imatest, in conjunction with
                a variety of image calibration charts. These sophisticated tools
                empower us to perform precise and comprehensive image quality
                assessments, ensuring that the images meet the stringent
                standards required for a wide array of applications, including
                but not limited to digital heritage preservation, medical
                imaging, and computer vision.
              </ReadMore>
            </div>
          </div>
          <br />

          <div className="row">
            <div className="col text-center">
              <img src={img1} className="img-fluid" alt="..." />
            </div>
          </div>
        </div>
        {/* related publications code */}
        <br />
        <div
          className="text-center fw-bold heading-font-size"
          style={{
            paddingBlockEnd: "1em",
          }}
        >
          Related Publications
        </div>
        <PublicationsFetch researchFilter="Quality Analysis of Images" />
        {/* related publications code */}
        <Footer />
      </div>
    </>
  );
}

export default TwoDimensionalPage5;
