import React from "react";
import { Routes, Route } from "react-router-dom";

// import Footer from "../Components/Common/Footer_Trial";
import TopPageScroll from "../Components/Common/TopPageScroll";
// import Career from "../Pages/Career";
import Home from "../Pages/Home";
import Team from "../Pages/Team.js";
import Projects from "../Pages/Projects";
import Publications from "../Pages/Publications";
import PublicationView from "../Pages/PublicationsView.js";
import Research from "../Pages/Research";
// import SEED from "../Pages/SEED";
import TwoDimensional from "../Pages/TwoDimensional";
import ThreeDimensional from "../Pages/ThreeDimensional";
import Multimodal from "../Pages/Multimodal";

import SeedTrial from "../Pages/SeedTrial";
import ContactUs from "../Pages/ContactUs";
// import Projects_page from "../Page_Layouts/Projects_page";
import SponsoredResearch from "../Pages/SponsoredResearch";
import Consultancy from "../Pages/Consultancy";
import AicteRps from "../Pages/AicteRps";
import IHDS from "../Pages/IHDS";
import Vaidya from "../Pages/Vaidya";
import DstDp from "../Pages/DstDp";
import TwoDimensionalPage1 from "../Pages/TwoDimensionalPage1";
import TwoDimensionalPage2 from "../Pages/TwoDimensionalPage2";
import TwoDimensionalPage3 from "../Pages/TwoDimensionalPage3";
import TwoDimensionalPage4 from "../Pages/TwoDimensionalPage4";
import TwoDimensionalPage5 from "../Pages/TwoDimensionalPage5";
import TwoDimensionalPage6 from "../Pages/TwoDimensionalPage6";
import ThreeDimensionalPage1 from "../Pages/ThreeDimensionalPage1";
import ThreeDimensionalPage2 from "../Pages/ThreeDimensionalPage2";
import ThreeDimensionalPage3 from "../Pages/ThreeDimensionalPage3";
import ThreeDimensionalPage4 from "../Pages/ThreeDimensionalPage4";
import ThreeDimensionalPage5 from "../Pages/ThreeDimensionalPage5";
import ThreeDimensionalPage6 from "../Pages/ThreeDimensionalPage6";
import Datasets from "../Pages/Datasets";
import DatasetPage from "../Pages/DatasetPage.js";
// import Dataset_RSUIGM from "../Pages/Dataset_RSUIGM";
import Footer from "../Pages/Footer.js";
import AboutUs from "../Pages/AboutUs";

import ErrorPage from "../Pages/ErrorPage.js"
// import Test from "../Pages/test";

// import { Link } from "react-router-dom";
// import Breadcrumbs from "../Components/Common/Breadcrumbs";


function Router() {
  // const location = useLocation();
  // const navigate = useNavigate();
  // const currentPath = location.pathname;

  return (
    <>
      <TopPageScroll />
      {/* <Breadcrumbs />  */}
      <Routes>
        {/* <Route exact path="/AboutUs" element={<About_Trial />} />
        <Route exact path="/career" element={<Career />} /> */}
        <Route path="/Error" element={<ErrorPage/>}/>
        <Route exact path="/contact" element={<ContactUs />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/AboutUs" element={<AboutUs />} />
        <Route exact path="/Team" element={<Team />} />
        <Route exact path="/Projects" element={<Projects />} />
        <Route exact path="/Publications" element={<Publications />} />
        <Route exact path="/Research" element={<Research />} />
        <Route path="/seed" element={<SeedTrial />} />
        <Route path="/publications_view" element={<PublicationView />} />
        <Route path="/research/2d" element={<TwoDimensional />} />
        <Route path="/research/3d" element={<ThreeDimensional />} />
        <Route path="/research/multimodal" element={<Multimodal />} />
        <Route path="/projects/Sponsored" element={<SponsoredResearch />} />
        <Route path="/projects/Consultancy" element={<Consultancy />} />
        <Route path="/projects/sponsored/aicte_rps" element={<AicteRps />} />
        <Route path="/projects/sponsored/ihds" element={<IHDS />} />
        <Route path="/projects/sponsored/vaidya" element={<Vaidya />} />
        <Route path="/projects/sponsored/dst-dp" element={<DstDp />} />
        <Route path="/Research/2d/Two_Dimensional_Page1" element={<TwoDimensionalPage1/>} />
        <Route path="/research/2d/Two_Dimensional_Page2" element={<TwoDimensionalPage2/>} />
        <Route path="/research/2d/Two_Dimensional_Page3" element={<TwoDimensionalPage3/>} />
        <Route path="/research/2d/Two_Dimensional_Page4" element={<TwoDimensionalPage4/>} />
        <Route path="/research/2d/Two_Dimensional_Page5" element={<TwoDimensionalPage5/>} />
        <Route path="/research/2d/Two_Dimensional_Page6" element={<TwoDimensionalPage6/>} />
        <Route path="/Research/3d/Three_Dimensional_Page1" element={<ThreeDimensionalPage1/>} />
        <Route path="/research/3d/Three_Dimensional_Page2" element={<ThreeDimensionalPage2/>} />
        <Route path="/research/3d/Three_Dimensional_Page3" element={<ThreeDimensionalPage3/>} />
        <Route path="/research/3d/Three_Dimensional_Page4" element={<ThreeDimensionalPage4/>} />
        <Route path="/research/3d/Three_Dimensional_Page5" element={<ThreeDimensionalPage5/>} />
        <Route path="/research/3d/Three_Dimensional_Page6" element={<ThreeDimensionalPage6/>} />
        <Route path="/dataset" element={<Datasets/>} />
        <Route path="/dataset_page" element={<DatasetPage/>} />
        <Route path="../Pages/Footer.js" element={<Footer />}/>
        {/* <Route path="*" element={<navigate to="/" />}/> */}
      </Routes>
    </>
  );
}

export default Router;
