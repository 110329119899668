import React from "react";
import Navbar from "../Components/Common/Navbar";
import about_background from "../Images/2D/Hero.webp";
import img1 from "../Images/2D/Detection and Localization.webp";
import "../Stylesheets/Publications.css";
import BackToTop from "../Components/Common/BackToTop";
import Footer from "../Pages/Footer.js";

import Breadcrumbs from "../Components/Common/Breadcrumbs";
import Hero from "../Components/Common/Hero.js";

import PublicationsFetch from "../Components/Publications/PublicationsFetchFunction.js";
import ReadMore from "../Components/Common/ReadMore.js";

function TwoDimensionalPage6() {
  return (
    <>
      <div className="min-vh-100" style={{ backgroundColor: "#F6F4F2" }}>
        <BackToTop />
        <Navbar />
        <br />
        <br />
        <br />

        <Hero
          title="Detection, Localization, and Tracking"
          subTitle=""
          img={about_background}
          showAnimationText="false"
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/a-computer-generated-image-of-a-pink-and-purple-wave-7FqOISWr5V0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />

        <br />
        <div
          className="container"
          style={{ paddingBlock: "1em", marginTop: "1em" }}
        >
          <Breadcrumbs />
          <div className="row">
            <div className="container content-font-size">
              <ReadMore>
                Our research endeavors revolve around the strategic design of
                algorithms and models tailored for the intricate tasks of
                detecting, localizing, and tracking objects or regions of
                interest within 2D visual data. These tasks encompass a spectrum
                of critical functionalities, such as object recognition, precise
                spatial localization, and the tracking of objects across time.
                The ability to achieve these tasks is pivotal in a multitude of
                applications, particularly in the domains of computer vision and
                robotics. By developing and refining these algorithms, we aim to
                empower a wide range of applications, from autonomous navigation
                in robotics to surveillance systems and augmented reality, where
                the detection, localization, and tracking of objects play a
                central role in enhancing human-machine interactions and
                decision-making processes.
              </ReadMore>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col text-center">
              <img src={img1} className="img-fluid" alt="..." />
            </div>
          </div>
        </div>
        {/* related publications code */}
        <br />
        <div
          className="text-center fw-bold heading-font-size"
          style={{
            paddingBlockEnd: "1em",
          }}
        >
          Related Publications
        </div>
        <PublicationsFetch researchFilter="Detection Localization and Tracking" />
        {/* related publications code */}
        <Footer />
      </div>
    </>
  );
}

export default TwoDimensionalPage6;
