import React from "react";
import Navbar from "../Components/Common/Navbar";
// import research_background from "../Images/Research/research-background.webp";
import research_background from "../Images/About_Us/Bannera.webp";
import Footer from "../Pages/Footer.js";
import Breadcrumbs from "../Components/Common/Breadcrumbs";
import Hero from "../Components/Common/Hero.js";

function Consultancy() {
  return (
    <>
      <div className="min-vh-100">
        <Navbar />
        <br />
        <br />
        <br />

        <Hero
          title="Consultancy Projects"
          img={research_background}
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/an-abstract-pink-background-with-wavy-lines-bloGOumd0Z4?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />

        <br />

        <div className="research-content" style={{ paddingTop: "1em" }}>
          <div className="container">
            <Breadcrumbs />

            <div className="col-sm-6 pt-3">
              <h3 className="heading-font-size fw-bold">Completed Projects</h3>
            </div>
            <div className="row my-5">
              <ol className="list-number-size">
                <div className="container">
                  <li>
                    <b className="sub-heading-font-size">
                      Samsung: Large scale reconstruction using Drone images (
                      2017-19)
                    </b>
                    <br />
                    <p className="content-font-size">
                      Objective is to develop pipeline for large scale 3D
                      reconstruction which includes 3D reconstruction pipeline
                      and 3D data processing methods and such the reconstructed
                      models can be used for AR/VR applications. We have
                      reconstructed more than 30 sites with a height ranging
                      from 7ft to 70ft.
                    </p>
                  </li>
                  <hr className="my-4" />
                  <li>
                    <b className="sub-heading-font-size">
                      Continental India Ltd: ‘Restoration of motion blur and
                      image SR for autonomous driving’ (2016-2019)
                    </b>
                    <br />
                    <p className="content-font-size">
                      We have developed early detection of signboards using
                      super-resolution of videos and removal of motional blur.
                    </p>
                  </li>
                </div>
              </ol>
            </div>
          </div>
        </div>

        <br />
        <br />


        <Footer />
      </div>
    </>
  );
}

export default Consultancy;
