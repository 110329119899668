import vaidya1 from "../Images/Vaidya/vaidya1.webp";
import React from "react";
import Navbar from "../Components/Common/Navbar";
import about_background from "../Images/About_Us/Bannera.webp";
import "../Stylesheets/Publications.css";
import BackToTop from "../Components/Common/BackToTop";
import Footer from "../Pages/Footer.js";
import Breadcrumbs from "../Components/Common/Breadcrumbs";
import Hero from "../Components/Common/Hero.js";
import PublicationsFetch from "../Components/Publications/PublicationsFetchFunction.js";
function Vaidya() {
  return (
    <>
      <div className="min-vh-100">
        <BackToTop /> <Navbar />
        <br />
        <br />
        <br />
        <Hero
          title="Vision Group of Science and Technology (VGST):"
          subTitle="V-Vaidya: Visual-Intelligence Based Analysis of Histopathology
                Data Towards the Detection of Oral Cancer"
          img={about_background}
          showAnimationText="false"
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/an-abstract-pink-background-with-wavy-lines-bloGOumd0Z4?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />
        <br />
        <div className="container" style={{ paddingTop: "1em" }}>
          <Breadcrumbs />

          <div className="row">
            <p className="content-font-size" style={{ paddingBottom: "1em" }}>
              The project "Visual-Intelligence Based Analysis of Histopathology
              Data Towards the Detection of Oral Cancer" focuses on developing a
              learning-based framework to assist in the analysis of
              histopathological data for the diagnosis of preoral and oral
              cancer. The project aims to leverage machine learning and
              artificial intelligence techniques to enhance the accuracy and
              efficiency of the diagnostic process, ultimately improving patient
              outcomes.{" "}
            </p>
          </div>
          <div className="row">
            <div className="col col-md text-center">
              <img src={vaidya1} className="img-fluid" alt="..." />
            </div>
          </div>
          <br />

          <div className="row">
            <div className="container">
              <p className="sub-heading-font-size text-black">
                Objectives of the Project "V-Vaidya Visual-Intelligence Based
                Analysis of Histopathology Data Towards the Detection of Oral
                Cancer":
              </p>
              <ol className="list-number-size">
                <li className="content-font-size">
                  Develop a learning-based framework: Design and implement a
                  comprehensive framework that utilizes machine learning and
                  artificial intelligence techniques to assist in the analysis
                  of histopathology data for the diagnosis of preoral and oral
                  cancer. The framework will leverage advanced algorithms to
                  enhance the accuracy and efficiency of the diagnostic process.
                </li>
                <li className="content-font-size">
                  Generate a dataset: Create a high-quality and diverse dataset
                  specifically tailored for AI-based histopathological analysis.
                  The dataset will include a wide range of preoral and oral
                  cancer cases, encompassing different stages, subtypes, and
                  variations. This dataset will serve as a valuable resource for
                  training and validating the developed framework.
                </li>
                <li className="content-font-size">
                  Develop an annotation tool: Build a user-friendly annotation
                  tool that facilitates the analysis of histopathological data.
                  The tool will enable pathologists and researchers to annotate
                  and label the key features and regions of interest in the
                  histopathology images. This annotated data will contribute to
                  the training and evaluation of the AI models within the
                  framework.
                </li>
                <li className="content-font-size">
                  Demonstrate and validate the results: Demonstrate the
                  effectiveness and reliability of the proposed framework in a
                  clinical setting. The framework will be applied to a
                  real-world dataset of histopathology data, and its performance
                  will be evaluated against established diagnostic standards.
                  The results will be compared with the expertise of human
                  pathologists to assess the framework's diagnostic accuracy and
                  potential for clinical deployment.
                </li>
                {/* <li>
              To demonstrate visual presentation of point cloud data by storytelling and virtual reality.
                   </li> */}
              </ol>
            </div>
          </div>
          {/* <div className="row">
              <div className="col-md-6">
                  <p>
                      
                  </p>
              </div>
              <div className="col-md-6">
                  <img src={img2} className="img-fluid" alt="Your Image"/>
              </div>
            
          </div> */}
        </div>
        {/* related publications code */}
        <br />
        <div className="text-center heading-font-size fw-bold ">
          Related Publications
        </div>
        <PublicationsFetch projectFilter="V-Vaidya" />
        {/* related publications code */}
        <Footer />
      </div>
    </>
  );
}

export default Vaidya;
