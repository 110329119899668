import React from "react";
import Navbar from "../Components/Common/Navbar";
import about_background from "../Images/2D/Hero.webp";
import img1 from "../Images/3D/3D Data Capture.webp";
import "../Stylesheets/Publications.css";
import BackToTop from "../Components/Common/BackToTop";
import Footer from "../Pages/Footer.js";
import Breadcrumbs from "../Components/Common/Breadcrumbs";
import Hero from "../Components/Common/Hero.js";

import ReadMore from "../Components/Common/ReadMore.js";
import PublicationsFetch from "../Components/Publications/PublicationsFetchFunction.js";

function ThreeDimensionalPage1() {
  return (
    <>
      <div className="min-vh-100" style={{ backgroundColor: "#F6F4F2" }}>
        <BackToTop />
        <Navbar />
        <br />
        <br />
        <br />

        <Hero
          title="3D Data Acquisition"
          subTitle=""
          img={about_background}
          showAnimationText="false"
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/a-computer-generated-image-of-a-pink-and-purple-wave-7FqOISWr5V0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />

        <br />
        <div
          className="container"
          style={{ paddingBlock: "1em", marginTop: "1em" }}
        >
          <Breadcrumbs />
          <div className="row">
            <div className="container content-font-size">
              <ReadMore>
                Our focus is fundamentally rooted in pioneering the realm of 3D
                data capture techniques and technologies. This initial phase of
                our work revolves around the strategic selection or even the
                custom design of cutting-edge sensors, LiDAR (Light Detection
                and Ranging) systems, and depth cameras. These technologies play
                a crucial role in acquiring 3D data that is not only accurate
                but also comprehensive in its scope. The process entails
                meticulous consideration of factors such as resolution, depth
                range, and sensor type to ensure the optimal acquisition of 3D
                data. By leveraging these advanced technologies, our research
                seeks to empower a wide array of applications, from 3D modeling
                and environmental mapping to augmented reality and autonomous
                navigation, where the precision and comprehensiveness of 3D data
                are paramount.
              </ReadMore>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col col-md text-center">
              <img src={img1} className="img-fluid" alt="..." />
            </div>
          </div>
          {/* <div className="row">
              <div className="col-md-6">
                  <p>
                      This is a paragraph. You can add your text here. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
              </div>
              <div className="col-md-6">
                  <img src={img2} className="img-fluid" alt="Your Image"/>
              </div>
          </div> */}
        </div>
        {/* related publications code */}
        <br />
        <br />
        <div
          className="text-center heading-font-size fw-bold"
          style={{
            marginTop: "1em",
            paddingBlockEnd: "1em",
          }}
        >
          Related Publications
        </div>
        <PublicationsFetch researchFilter="3D Data Acquisition" />
        {/* related publications code */}
        <Footer />
      </div>
    </>
  );
}

export default ThreeDimensionalPage1;
