import ihds1 from "../Images/IHDS/ihds1.webp";
// import ihds2 from "../Images/IHDS/ihds2.webp";
// import ihds3 from "../Images/IHDS/ihds3.webp";
// import ihds4 from "../Images/IHDS/ihds4.webp";
// import ihds5 from "../Images/IHDS/ihds5.webp";
// import ihds6 from "../Images/IHDS/ihds6.webp";
// import ihds7 from "../Images/IHDS/ihds7.webp";
// import ihds8 from "../Images/IHDS/ihds8.webp";
// import ihds9 from "../Images/IHDS/ihds9.webp";
import React from "react";
import Navbar from "../Components/Common/Navbar";
import about_background from "../Images/About_Us/Bannera.webp";
import "../Stylesheets/Publications.css";
import BackToTop from "../Components/Common/BackToTop";
import Footer from "../Pages/Footer.js";
import Breadcrumbs from "../Components/Common/Breadcrumbs";
import Hero from "../Components/Common/Hero.js";

import ReadMore from "../Components/Common/ReadMore.js";
import PublicationsFetch from "../Components/Publications/PublicationsFetchFunction.js";

function IHDS() {
  return (
    <>
      <div className="min-vh-100">
        <BackToTop />
        <Navbar />
        <br />
        <br />
        <br />
        <Hero
          title="DST Sponsored:"
          subTitle="Crowdsourcing Framework for Digital Archiving and Presentation
            of Cultural Heritage"
          img={about_background}
          showAnimationText="false"
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/an-abstract-pink-background-with-wavy-lines-bloGOumd0Z4?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />
        <br />
        <div className="container" style={{ paddingTop: "1em" }}>
          <Breadcrumbs />

          <div className="row">
            <div className="content-font-size" style={{ paddingBottom: "1em" }}>
              <ReadMore>
                The main objective of this project is to develop a crowdsourcing
                framework as a solution towards digital archiving and
                preservation of Cultural Heritage (CH) sites. Digital archiving
                and preservation of both tangible and intangible cultural
                heritage is a laborious task involving multitude of processes
                ranging from capturing data with various modalities and storing
                historical CH data. India is a nation with rich cultural history
                and boasts plenty of CH sites, which are essential for digital
                archiving and preservation to attract researchers and tourism,
                however, most of the data essential for the preservation is
                scattered, which makes digital archiving and preservation an
                insurmountable task. Towards this we propose to build a
                crowdsourcing framework which guides or assists "the crowd" in
                acquiring and uploading the historical data by providing a
                crowdsourcing platform and (semi-) automatically processing the
                data for preserving, reconstruction and recreation of the
                archived data as well as to develop demonstrable content of the
                CH sites.
              </ReadMore>
            </div>
          </div>
          <div className="row">
            <div className="col col-md text-center">
              <img src={ihds1} className="img-fluid" alt="IHDS" />
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="container">
              <span className="sub-heading-font-size text-black">
                {" "}
                This we develop the following:
              </span>
              <ol className="list-number-siz">
                <li className="content-font-size">
                  To set up a web-based crowdsourcing platform to assist users
                  to acquire and upload data: Designed and developed a
                  crowdsourcing platform capable of assisting users to upload
                  the acquired data towards archival.
                </li>
                <li className="content-font-size">
                  To develop a solution for data clean-up, filtering and
                  structuring of crowdsourced data: To make the data suitable
                  for 3D reconstruction and presentation, we propose number of
                  methods to refine the data like removal of shadow, occlusions,
                  blur, removal of images taken from similar camera positions,
                  categorization of images for a particular heritage site,
                  selection images for 3D reconstruction etc.
                </li>
                <li className="content-font-size">
                  To set up a 3D reconstruction pipeline as an efficient
                  operational procedure to easily replicate generating
                  demonstrable content for the CH sites from the acquired data
                  with minimal training. Propose algorithms for refinement of
                  generated 3D models (Point cloud representations) with number
                  of 3D data processing methods like hole detection, hole
                  filling and super resolution of generated point cloud.
                </li>
                <li className="content-font-size">
                  To demonstrate digital reconstruction/recreation of heritage
                  through 3D reconstruction from crowdsourced data: We
                  demonstrate the 3D reconstructions of cultural heritage
                  through AR and VR.
                </li>
                {/* <li>
              To demonstrate visual presentation of point cloud data by storytelling and virtual reality.
                   </li> */}
              </ol>
            </div>
          </div>
          {/* <div className="row">
              <div className="col-md-6">
                  <p>
                      
                  </p>
              </div>
              <div className="col-md-6">
                  <img src={img2} className="img-fluid" alt="Your Image"/>
              </div>
            
          </div> */}
        </div>
        {/* related publications code */}
        <br />
        <div className="text-center heading-font-size my-4 fw-bold">
          Related Publications
        </div>
        <PublicationsFetch projectFilter="IHDS" />
        {/* related publications code */}
        <Footer />
      </div>
    </>
  );
}

export default IHDS;
