import React from "react";
import Navbar from "../Components/Common/Navbar";
import about_background from "../Images/2D/Hero.webp";
import "../Stylesheets/Publications.css";
import BackToTop from "../Components/Common/BackToTop";
import Footer from "../Pages/Footer.js";

import Breadcrumbs from "../Components/Common/Breadcrumbs";
import Hero from "../Components/Common/Hero.js";

import ReadMore from "../Components/Common/ReadMore.js";

import PublicationsFetch from "../Components/Publications/PublicationsFetchFunction.js";

function TwoDimensionalPage1() {
  return (
    <>
      <div className="min-vh-100">
        <BackToTop />
        <Navbar />
        <br />
        <br />
        <br />
        <Hero
          title="2D Data Acquisition / Capture Setup"
          subTitle=""
          img={about_background}
          showAnimationText="false"
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/a-computer-generated-image-of-a-pink-and-purple-wave-7FqOISWr5V0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />

        <br />
        <div
          className="container"
          style={{ paddingBlock: "1em", marginTop: "1em" }}
        >
          <Breadcrumbs />
          <div className="row">
            <div className="container content-font-size">
              <ReadMore>
                Our endeavors are centered around empowering the creation of
                advanced 2D data capture setups. This intricate process
                encompasses the thoughtful selection of visual sensors, cameras,
                and comprehensive imaging systems. The ultimate objective is to
                guarantee the acquisition of data of the highest quality and
                precision. To achieve this, we meticulously evaluate a range of
                crucial factors. These include resolution, where we seek to
                ensure that our data capture setup delivers the level of detail
                required for our specific application. We also pay close
                attention to frame rate, tailoring it to the dynamics of the
                scenes we aim to capture, and sensor type, which is selected
                judiciously to harmonize with the unique requirements of our
                data collection. By meticulously considering these elements, we
                optimize our data acquisition process, ultimately enabling the
                acquisition of high-quality data for a wide array of
                applications.
              </ReadMore>
            </div>
          </div>
          <div className="row">
            {/* <img src="your-image.webp" className="img-fluid" alt="Your Image"/> */}
          </div>
        </div>
        {/* <div className="container">
          <div className="row" style={{ paddingBlock: "2em" }}>
            <div
              className="col-md d-flex justify-content-center align-items-center p-0"
              style={{
                backgroundColor: "",
                overflowY: "visible",
                minHeight: "20vh",
              }}
            >
              <p
                className=""
                style={{
                  fontSize: "5vh",
                  color: "#05445E",
                  fontWeight: "500",
                }}
              >
                Who we are, <br /> What we do, <br /> Why we do it
              </p>
            </div>
            <div
              className="col d-flex align-items-center p-0"
              style={{ textAlign: "left" , lineHeight: "2" }}
            >
              <p>
                Center of Excellence in Visual Intelligence (CEVI) earlier known
                as Computer Vision and Graphics (CVG) lab is a research center
                with the focus towards creating a renowned, state-of-the-art
                facility equipped to create innovative visual intelligence
                solutions. The CEVI has research footprints in the area of
                artificial intelligence and data engineering through its
                collaborations, funded projects since 2009. CEVI conducts summer
                and winter workshops (Visual intelligence workshop) every year,
                catering 500 plus students and faculty across institutes. The
                faculty and students at CEVI have published papers in renowned
                journals and conferences like TPAMI, MVA, CGJ, JNN, CVPR, ICCV,
                SIGGRAPH, SIGGRAPH ASIA, NeurIPS, ICPR, ICDAR, ACCV, BMVC.
              </p>
            </div>
          </div>
          </div> */}
        {/* related publications code */}
        <br />
        {/* <div
              className="title"
              style={{
                textAlign: "center",
                fontSize: "5vh",
                paddingBlockEnd: "1em",
                color: "#05445E",
              }}
            >
              Related Publications
            </div> */}

        <PublicationsFetch researchFilter="2D Data Acquisition" />

        {/* related publications code */}
        <br />
        <br />


        <Footer />
      </div>
    </>
  );
}

export default TwoDimensionalPage1;
