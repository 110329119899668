// import React, { useContext, useEffect, useState, useRef } from "react";
import React from "react";
import "../Stylesheets/About_Us.css";
// import NETS from "vanta/dist/vanta.net.min";
// import placeholder from "../Images/About_Us/placeholder.webp";
import img from "../Images/Dataset/topbg1.webp";
import "bootstrap/dist/css/bootstrap.min.css";
// import Button1 from "../Components/Home/Button1";
// import about3 from "../Images/About_Us/about3.webp";
import BackToTop from "../Components/Common/BackToTop";
import Footer from "../Pages/Footer.js";
import Navbar from "../Components/Common/Navbar";
// import about_background from "../Images/About_Us/about-background.webp";
import Hero from "../Components/Common/Hero.js";

function AboutUs() {
  return (
    <>
      <div className="about-background main d-flex flex-column min-vh-75">
        <BackToTop />
        <Navbar />
        <br />
        <br />
        <br />

        <Hero
          title="About Us"
          img={img}
          creatorLink="https://unsplash.com/@maxberg?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Maxim Berg"
          imageLink="https://unsplash.com/photos/a-blue-and-white-swirl-on-a-light-blue-background-jlDK7el6sEM?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-12 mt-5 pt-4">
              <h4 className="heading-font-size">
                What we are + What we do + Why we do it
              </h4>
              <br />
              <div className="col-9 col-md-6 col-xl-5 content-font-size">
                {/* <span className="content-font-size"> */}
                  To be pioneers in visual intelligence by providing
                  <b>
                    <i>
                      {" "}
                      innovative environment, capture capabilities, outstanding
                      research experience, global recognition, and research
                      excellence{" "}
                    </i>
                  </b>{" "}
                  with an impact on the industry and society.
                {/* </span> */}
              </div>
              <div className="col-9 col-md-6 col-xl-5 border-bottom border-black p-4 border-3"></div>
            </div>
          </div>
          <div className="row mt-5 mb-5 justify-content-center">
            <div className="col-12 col-lg-3">
              <h4 className="heading-font-size">
                Dissecting the
                <b> impact</b>
              </h4>
              <span className="text-break content-font-size">
                Witness the numbers, narrate our tale of disruption with data
              </span>
            </div>
            <div className="col-lg-8 col-sm-12 ms-lg-5 align-content-center">
              <div className="row pt-4 text-center d-flex g-3">
                <div className="col-6 col-lg-3">
                  <div
                    className="py-3 equal-boxes"
                    style={{ backgroundColor: "#D9D9D9" }}
                  >
                    <b style={{ fontSize: "calc(0.9rem + 0.2vw)" }}>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "calc(1.9rem + 0.2vw)",
                          color: "#0870D8",
                        }}
                      >
                        5+
                      </span>
                      <br />
                      Startups
                    </b>
                  </div>
                </div>
                <div className="col-6 col-lg-3">
                  <div
                    className="py-3 equal-boxes"
                    style={{ backgroundColor: "#D9D9D9" }}
                  >
                    {" "}
                    <b style={{ fontSize: "calc(0.9rem + 0.2vw)" }}>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "calc(1.9rem + 0.2vw)",
                          color: "#0870D8",
                        }}
                      >
                        100+
                      </span>
                      <br />
                      Publications
                    </b>
                  </div>
                </div>
                <div className="col-6 col-lg-3">
                  <div
                    className="py-3 equal-boxes"
                    style={{ backgroundColor: "#D9D9D9" }}
                  >
                    {" "}
                    <b style={{ fontSize: "calc(0.9rem + 0.2vw)" }}>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "calc(1.9rem + 0.2vw)",
                          color: "#0870D8",
                        }}
                      >
                        13+
                      </span>
                      <br />
                      Founded projects
                    </b>
                  </div>
                </div>
                <div className="col-6 col-lg-3">
                  <div
                    className="py-3 equal-boxes"
                    style={{ backgroundColor: "#D9D9D9" }}
                  >
                    {" "}
                    <b style={{ fontSize: "calc(0.9rem + 0.2vw)" }}>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "calc(1.9rem + 0.2vw)",
                          color: "#0870D8",
                        }}
                      >
                        1000+
                      </span>
                      <br />
                      Students Trained
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <h4 className="p-1 heading-font-size">
            Our work <b>showreel</b>
          </h4>
          <div className="d-flex justify-content-center">
            <iframe
              className="iframe-box w-100"
              src="https://www.youtube.com/embed/y4hrckxYIUg?si=7qYyCYZTqhaXJOBU&amp;controls=0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              cc_load_policy="0"
            ></iframe>
          </div>
          {/* <h4 className="mt-5" style={{ fontSize: "calc(1.4rem + 0.2vw)" }}>
            <b>Any Query? Email Us!</b>
          </h4> */}

          {/* <div className="row mb-3">
            <div className="col-12 col-md-6 border-0 mb-3">
              <input
                type="email"
                className="col-12 col-md-6 form-control border-0 border-bottom border-dark"
                placeholder="Drop your Email Address"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
              />
            </div>
            <div className="btn_class col-md-2 justify-content-end mt-2">
              <Button1 className="" title="Email" />
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AboutUs;
