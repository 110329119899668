import React from "react";
import "../../Stylesheets/Datasets_Cards.css";
import { Link } from "react-router-dom";

function Cards(props) {
  return (
    <>
      <div className="col card h-70 research-cards rounded-4 border-0 proj-card-body">
        <Link
          to={props.link}
          className="stretched-link text-black text-decoration-none"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="row-6 card-body px-4 pt-3 pb-1">
            <div
              className="row-2 card-title text-center sub-heading-font-size proj-card-link"
              style={{ height: "30px" }}
            >
              {props.title}
            </div>
            <div className="row-2">
              <p className="card-text content-font-size proj-card-desc">
                {props.desc}
              </p>
            </div>
          </div>
          <img
            src={props.img}
            className="row-5 bg-white card-img-bottom img-fluid rounded-top-0 rounded-4  h-100 proj-card-img"
            alt="..."
          />
        </Link>
      </div>
    </>
  );
}

export default Cards;

// import React from "react";
// import "../../Stylesheets/Datasets_Cards.css";
// import Buttond from "../Datasets/DatasetsButton.js";
// import { Link } from "react-router-dom";

// function Cards(props) {
//   return (
//     <>
//       {/* 1st row */}
//       <Link
//       to={props.link}
//       className="text-decoration-none">

//       <div
//         className="card h-70 research-cards rounded-4 border-0 "
//         style={{
//           // height:"30vh",
//           marginInline: "1.5em",
//           maxWidth: "100%",
//           backgroundColor: "#FFFFFF",
//         }}
//       >
//         <img
//           // style={{height:"40vh"}}
//           src={props.img}
//           className=" bg-white card-img-bottom img-fluid rounded-top-0 rounded-3  h-150 "
//           alt="..."
//         />
//         <div className="card-body p-0 !important mt-4">
//           <div
//             className="card-title pb-2 card-title-fs text-black d-flex text-center justify-content-center"
//             style={{
//               fontWeight: "500",
//               lineHeight: "1",
//               color: "#F6F4F2",
//             }}
//           >
//             {/* <Link
//               // to={props.link}
//               style={{ textDecoration: "none" }}
//               className="stretched-link text-black d-flex"
//             > */}
//             {props.title}

//             {/* {!props.link ? (
//               ""
//             ) : (
//               // <a href={props.link} className="ms-auto">
//               //   <Buttond className="Download_button" title="Download" />
//               // </a>
//             )} */}

//             {/* </Link> */}
//           </div>
//           <p
//             className="card-text fw-medium mt-3"
//             style={{
//               textAlign: "justify",
//               lineHeight: "1.2",
//               color: "#777777",
//             }}
//           >
//             {props.desc}
//           </p>
//         </div>
//       </div>
//       </Link>
//     </>
//   );
// }

// export default Cards;
