import React from "react";
import Navbar from "../Components/Common/Navbar";
// import { TypeAnimation } from "react-type-animation";
import about_background from "../Images/2D/Hero.webp";
import img1 from "../Images/3D/MultiView 3D Reconstruction.webp";
import "../Stylesheets/Publications.css";
import BackToTop from "../Components/Common/BackToTop";
import Footer from "../Pages/Footer.js";

import Breadcrumbs from "../Components/Common/Breadcrumbs";
import Hero from "../Components/Common/Hero.js";

import ReadMore from "../Components/Common/ReadMore.js";
import PublicationsFetch from "../Components/Publications/PublicationsFetchFunction.js";

function ThreeDimensionalPage2() {
  return (
    <>
      <div className="min-vh-100" style={{ backgroundColor: "#F6F4F2" }}>
        <BackToTop />
        <Navbar />
        <br />
        <br />
        <br />

        <Hero
          title="3D Reconstruction and Recreation"
          subTitle=""
          img={about_background}
          showAnimationText="false"
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/a-computer-generated-image-of-a-pink-and-purple-wave-7FqOISWr5V0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />

        <br />
        <div
          className="container"
          style={{ paddingBlock: "1em", marginTop: "1em" }}
        >
          <Breadcrumbs />
          <div className="row">
            <div className="container content-font-size">
              <ReadMore>
                A significant facet of our research involves the intricate task
                of reconstructing and recreating 3D scenes or objects based on
                the data we've acquired. This multifaceted process entails the
                transformation of raw data, often in the form of point clouds or
                depth maps, into detailed and accurate 3D models. These models
                are instrumental in providing a more faithful representation of
                the real-world environment, capturing intricate details and
                spatial relationships. The ability to construct such 3D models
                is vital in a wide array of applications, including
                architectural design, virtual reality, and archaeological
                preservation. Our research is dedicated to refining the
                techniques and algorithms involved in this process to ensure
                that the resulting 3D representations are as precise and
                informative as possible, contributing to advancements across
                numerous fields where 3D data holds significance.
              </ReadMore>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col col-md text-center">
              <img src={img1} className="img-fluid" alt="..." />
            </div>
          </div>
          {/* <div className="row">
              <div className="col-md-6">
                  <p>
                      This is a paragraph. You can add your text here. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
              </div>
              <div className="col-md-6">
                  <img src={img2} clasclassNames="img-fluid" alt="Your Image"/>
              </div>
          </div> */}
        </div>

        {/* related publications code */}
        <br />
        <br />
        <div
          className="text-center heading-font-size fw-bold"
          style={{
            paddingBlockEnd: "1em",
            marginTop: "1em",
          }}
        >
          Related Publications
        </div>
        <PublicationsFetch researchFilter="3D Reconstruction and Recreation" />
        {/* related publications code */}
        <Footer />
      </div>
    </>
  );
}

export default ThreeDimensionalPage2;
