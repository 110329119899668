import React from "react";
import "../../Stylesheets/PublicationCard.css";
// import { Link } from "react-router-dom";
// import PButton from "../Publications/PublicationButton.js";

function PublicationCard(props) {
  return (
    <div className="myTable mb-0">
      <div
        className="pub-cards  card border border-0 mb-4  rounded-0  "
        style={{ backgroundColor: "#F6F4F2" }}
      >
        <div className="row">
          <div className="col-12 col-md-3 d-flex justify-content-center align-items-center">
            <img
              src={props.image}
              className="img-fluid rounded-4"
              alt="..."
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            />{" "}
          </div>

          <div className="col-md align-content-center">
            <div className="card-body">
              <h5 className="card-title text-black fw-bold mb-2 sub-heading-font-size">
                {props.title}
              </h5>
              <div className="card-text mb-2">
                <small
                  className="fw-semibold publications-organisation-font-size card-subheading-opacity"
                  style={{
                    color: "#0870d8",
                    lineHeight: "1.7",
                  }}
                >
                  {props.org}
                </small>
              </div>
              <p
                className="card-subtitle mb-3 publications-author-font-size"
                style={{
                  lineHeight: "1.7",
                }}
              >
                {props.authors}
              </p>
              <div style={{cursor:"default"}}>
                <a
                  href={props.pdf}
                  style={{
                    color: "#212529",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  className={`fw-medium publication-hover btn btn-link ${
                    props.pdf ? "" : "disabled"
                  } p-0`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PDF
                </a>
                {" | "}
                <a
                  href={props.code}
                  style={{
                    color: "#212529",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  className={`fw-medium publication-hover btn btn-link ${
                    props.code ? "" : "disabled"
                  } p-0`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Code
                </a>
                {" | "}
                <a
                  href={props.video}
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                    color: "#212529",
                  }}
                  className={`fw-medium publication-hover btn btn-link ${
                    props.video ? "" : "disabled"
                  } p-0`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Video
                </a>

                {/* <button type="button" className="btn btn-outline-info p-1 px-2 me-2 rounded-0">
                  <a
                    href={props.pdf}
                    style={{
                      textDecoration: "none",
                    }}
                    target="_blank"
                  >
                    PDF
                  </a>
                </button>
                <button type="button" className="btn btn-outline-info p-1 px-2 me-2 rounded-0">
                  <a
                    href={props.code}
                    style={{
                      textDecoration: "none",
                    }}
                    target="_blank"
                  >
                    Code
                  </a>
                </button>
                <button type="button" className="btn btn-outline-info p-1 px-2 rounded-0">
                  <a
                    href={props.video}
                    style={{
                      textDecoration: "none",
                    }}
                    target="_blank"
                  >
                    Video
                  </a>
                </button> */}
              </div>

              {/* <div className="" style={{}}>
                <Link to={props.link}>
                  <PButton className="" title="View" />
                </Link>
              </div> */}

              <p className="visually-hidden">Type: {props.type}</p>
              <p className="visually-hidden">Topics: {props.topics}</p>
              <p className="visually-hidden">All</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublicationCard;
