import React, { useContext, useEffect, useState } from "react";
import PublicationCard from "./PublicationCards.js";
import $ from "jquery";
import jsPDF from "jspdf";
import placeholder from "../../Images/Publications/no-image-found.webp";
import yearContext from "../../Global varaibles/Year_filter/Year_context";
import categoryContext from "../../Global varaibles/Category_filter/Category_context";
import paperTypeContext from "../../Global varaibles/PaperType_filter/PaperType_context.js";
import Spinner from "./Spinner.js";

export default function PublicationsFetch({ researchFilter, projectFilter }) {
  const context = useContext(categoryContext);
  const yearcontext = useContext(yearContext);
  const typecontext = useContext(paperTypeContext);

  const { category } = context;
  const { year } = yearcontext;
  const { paperType } = typecontext;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const jQueryCode = () => {
    $(document).ready(function () {
      $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        $(".myTable").each(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    $("#pdf_download").click(function () {
      var doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [4, 2],
      });

      var source = window.document.getElementsByTagName("div")[14];
      doc.html(source);
      doc.save("two-by-four.pdf");
    });
  };

  const loadExcel = async () => {
    try {
      const response = await fetch("https://cevi.co.in/publication_react.php");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const json = await response.json();
      json.shift(); // Remove the header row if present
      setData(json);
      setLoading(false); // Stop loading when data is fetched
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false); // Stop loading in case of error
    }
  };

  useEffect(() => {
    jQueryCode();
    loadExcel();
  }, []);

  const filteredData = data.filter((t) => {
    const includesYear = t[3] && t[3].toString().includes(year);
    const includesCategory = t[9] && t[9].includes(category);
    const includesPaperType = t[6] && t[6].includes(paperType);
    const validEntry =
      t[0] && t[3] && t[6] && t[9] && t[1] !== "Paper Title";
    const includesProject = t[14] !== null;

    if (researchFilter) {
      const includesTopic =
        t[9] &&
        t[9].split(", ").some((topic) => topic === researchFilter);
      return (
        validEntry &&
        includesYear &&
        includesCategory &&
        includesPaperType &&
        includesTopic
      );
    }

    if (projectFilter) {
      const includesTopic =
        t[14] &&
        t[14].split(", ").some((topic) => topic === projectFilter);
      return (
        validEntry &&
        includesYear &&
        includesCategory &&
        includesPaperType &&
        includesTopic &&
        includesProject
      );
    }

    // Default return if no filters are applied
    return validEntry && includesYear && includesCategory && includesPaperType;
  });

  return (
    <div className="container">
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner /> 
        </div>
      ) : filteredData.length > 0 ? ( 
        filteredData.map((t, index) => {
          let image = placeholder;
          try {
            image = require(`../../publication_thumbnails/${t[13]}.webp`);
          } catch {}
          try {
            image = require(`../../publication_thumbnails/${t[ 13]}.png`);
          } catch {}

          return (
            <PublicationCard
              key={index}
              image={image}
              title={t[1]}
              org={`${t[7]}, ${t[2]} - ${t[3]}`}
              authors={t[4]}
              // link={t[8]}
              pdf={t[8]}
              code={t[11]}
              video={t[10]}
              type={t[6]}
              topics={t[9]}
            />
          );
        })
      ) : (
        <div className="fs-1 d-flex justify-content-center">
          No results found.
        </div>
      )}
    </div>
  );
}