import rps1 from "../Images/AICTE_RPS/rps1.webp";
import React from "react";
import Navbar from "../Components/Common/Navbar";
import about_background from "../Images/About_Us/Bannera.webp";
import "../Stylesheets/Publications.css";
import BackToTop from "../Components/Common/BackToTop";
import Footer from "../Pages/Footer.js";
import Breadcrumbs from "../Components/Common/Breadcrumbs";
import "../index.css";
import Hero from "../Components/Common/Hero.js";

import ReadMore from "../Components/Common/ReadMore.js";
import PublicationsFetch from "../Components/Publications/PublicationsFetchFunction.js";
function AicteRps() {
  return (
    <>
      <div className="min-vh-100">
        <BackToTop /> <Navbar />
        <br />
        <br />
        <br />
        <Hero
          title="AICTE-Research Promotion Scheme (RPS):"
          subTitle="Shape Representation, Reconstruction and Rendering of 3D Models"
          img={about_background}
          showAnimationText="false"
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/an-abstract-pink-background-with-wavy-lines-bloGOumd0Z4?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />
        <br />
        <div className="container" style={{ paddingTop: "1em" }}>
          <Breadcrumbs />

          <div className="row">
            <div className="content-font-size" style={{ paddingBottom: "1em" }}>
              <ReadMore>
                The 3D data acquired using scanning devices have one of the
                challenging issue i.e., missing regions or holes due to scanning
                devices or damaged models. The acquired point cloud data exhibit
                non-uniform distribution of geometry and hence we propose a set
                of 3D shape representation methods that will capture the
                inherent geometry of the acquired 3D data. We have proposed two
                approaches, namely, (A) rigid and non-rigid geometry-based
                conventional feature descriptors using metric tensor and
                Christoffel symbols, and wave kernel signature and (B)
                convolution architecture for decomposition of point clouds in
                primitive shapes. Next, in order to address the issues of
                missing regions or damaged models, we propose efficient data
                processing algorithms. This is a two-fold problem, namely, (A)
                hole detection where a learning-based approach is proposed for
                automatic detection of hole boundaries and (B) inpainting the
                missing regions under progress of complex 3D models. These are
                then considered for building 3D reconstruction pipeline focusing
                on shape completion. Towards this, we focus on a coarse-to-fine
                3D reconstruction pipeline for shape completion of point cloud
                data. We propose two methods to perform (A) edge-aware
                up-sampling on sparse point-clouds, (B) up-sampling of point
                cloud and its associated features, and (C) Densification of
                depth using RGB and sparse point cloud towards the refinement of
                structural details in 3D models. The refined model needs to be
                rendered and presented using VR which is in progress.
              </ReadMore>
            </div>
          </div>
          <div className="row">
            <div className="col col-md text-center my-2">
              <img src={rps1} className="img-fluid" alt="AICTE" />
            </div>
          </div>
          <br />

          <div className="row">
            <div className="container">
              <span className="tect-balck sub-heading-font-size">
                {" "}
                Objectives of the Project:
              </span>
              <ol className="list-number-size">
                <li className="content-font-size">
                  To propose geometry-based features for 3D shape
                  representation, which capture the inherent geometry of the
                  point cloud data.
                </li>
                <li className="content-font-size">
                  To develop exemplar-based 3D inpainting algorithms to in-paint
                  the missing regions of the point cloud data.
                </li>
                <li className="content-font-size">
                  To develop a coarse-to-fine 3D reconstruction framework for
                  shape completion of point cloud data.
                </li>
                <li className="content-font-size">
                  To develop a point-based rendering technique in an attempt to
                  restore the original appearance of reconstructed point cloud
                  data.
                </li>
                <li className="content-font-size">
                  To demonstrate visual presentation of point cloud data by
                  storytelling and virtual reality.
                </li>
              </ol>
            </div>
          </div>
        </div>
        {/* related publications code */}
        <br />
        <div className="text-center fw-bold heading-font-size ">
          Related Publications
        </div>
        <PublicationsFetch projectFilter="RPS" />
        {/* related publications code */}
        <Footer />
      </div>
    </>
  );
}

export default AicteRps;
