import React from "react";
import Navbar from "../Components/Common/Navbar";
import about_background from "../Images/2D/Hero.webp";
import img1 from "../Images/3D/Denoising.webp";
import img2 from "../Images/3D/Upsampling.webp";
import "../Stylesheets/Publications.css";
import BackToTop from "../Components/Common/BackToTop";
import Footer from "../Pages/Footer.js";

import Breadcrumbs from "../Components/Common/Breadcrumbs";
import Hero from "../Components/Common/Hero.js";

import PublicationsFetch from "../Components/Publications/PublicationsFetchFunction.js";

function ThreeDimensionalPage4() {
  return (
    <>
      <div className="min-vh-100" style={{ backgroundColor: "#F6F4F2" }}>
        <BackToTop />
        <Navbar />
        <br />
        <br />
        <br />

        <Hero
          title="Refinement of 3D Data"
          subTitle=""
          img={about_background}
          showAnimationText="false"
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/a-computer-generated-image-of-a-pink-and-purple-wave-7FqOISWr5V0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />

        <br />
        <div
          className="container"
          style={{ paddingBlock: "1em", marginTop: "1em" }}
        >
          <Breadcrumbs />
          <div className="row">
            <div className="container content-font-size">
              Our research is devoted to the development of advanced techniques
              for enhancing and preprocessing 3D data to elevate its overall
              quality and reliability. This multifaceted process involves the
              application of various methods such as denoising, calibration, and
              alignment. Denoising procedures aim to eliminate unwanted noise
              and imperfections in the acquired 3D data, ensuring that the
              resulting models are more accurate and dependable. Calibration
              techniques are used to fine-tune the data, rectifying any
              systematic errors or discrepancies in measurements. Additionally,
              alignment procedures play a critical role in ensuring that data
              from different sources or sensors are seamlessly integrated and
              coherent. By meticulously implementing these techniques, we aspire
              to provide 3D data that is not only more visually pleasing but
              also substantially more precise and consistent. These enhancements
              have significant implications across various fields, including 3D
              printing, cultural heritage preservation, and industrial quality
              control, where the accuracy and fidelity of 3D data are of utmost
              importance.
            </div>
          </div>
            <br />
          <div className="row">
            <div className="col col-md text-center">
              <img src={img1} className="img-fluid" alt="..." />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="container ">
              Point cloud denoising methods are carefully developed and applied
              to eliminate unwanted noise and imperfections within the data. By
              systematically reducing noise, our aim is to provide cleaner and
              more precise point cloud data, enhancing its utility across
              various domains, including environmental mapping, autonomous
              navigation, and 3D modeling, where the accuracy and clarity of 3D
              data are essential for informed decision-making and analysis.
              Point cloud upsampling methods are meticulously developed and
              implemented to increase the density of points within the data,
              resulting in a finer level of detail. Through our work in point
              cloud upsampling, we strive to provide more comprehensive and
              detailed 3D data that can support a wide range of applications,
              ultimately contributing to advancements in diverse fields.
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col col-md text-center">
              <img src={img2} className="img-fluid" alt="..." />
            </div>
          </div>
        </div>
        {/* related publications code */}
        <br />
        <br />
        <div
          className="text-center heading-font-size fw-bold"
          style={{
            paddingBlockEnd: "1em",
            marginTop: "1em",
          }}
        >
          Related Publications
        </div>
        <PublicationsFetch researchFilter="Refinement of 3D Data" />
        {/* related publications code */}
        <Footer />
      </div>
    </>
  );
}

export default ThreeDimensionalPage4;
