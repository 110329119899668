import React, { useEffect } from "react";
import "../../Stylesheets/Carousel_Item.css";

function CarouselItem(props) {
  useEffect(() => {
    const textContainers = document.querySelectorAll('.text-container');

    textContainers.forEach(container => {
      container.addEventListener('mouseleave', function() {
        // Reset scroll position to top when mouse leaves
        container.scrollTo({
          top: 0,
          behavior: 'smooth' // Smooth scroll behavior
        });
      });
    });

    // Cleanup event listeners on component unmount
    return () => {
      textContainers.forEach(container => {
        container.removeEventListener('mouseleave', function() {
          container.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        });
      });
    };
  }, []);

  return (
    <div className="carousel-card">
      <div className="card rounded-5 border-0 h-100 p-3 bg-colour">
        <div className="row g-2">
          <div className="col-3 col-sm-3 col-xl-3 justify-content-center">
            <img src={props.img} className="img-fluid p-1 mt-1 image-style" alt="Profile" />
          </div>
          <div className="col-9 col-sm-9 col-xl-9 d-flex flex-column justify-content-center">
            <span className="person-name">{props.name}</span>
            <span className="text-muted">{props.title}</span>
          </div>
        </div>
          <div className="card-body  mt-3 mb-2 ">
            <p className="text-break content-font-size elipsis-testimonials text-container">
              <span className="quote-mark start-quote">&ldquo;</span>
              {props.testimonial}
              <span className="quote-mark end-quote ms-2">&rdquo;</span>
            </p>
          </div>
      </div>
    </div>
  );
}

export default CarouselItem;
