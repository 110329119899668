import React from "react";
import Navbar from "../Components/Common/Navbar";
import about_background from "../Images/2D/Hero.webp";
import img1 from "../Images/2D/Categorization.webp";
import img2 from "../Images/2D/Filtering.webp";
import "../Stylesheets/Publications.css";
import BackToTop from "../Components/Common/BackToTop";
import Footer from "../Pages/Footer.js";
import Breadcrumbs from "../Components/Common/Breadcrumbs";
import Hero from "../Components/Common/Hero.js";

// import ReadMore from "../Components/Common/ReadMore.js";
import PublicationsFetch from "../Components/Publications/PublicationsFetchFunction.js";

function TwoDimensionalPage4() {
  return (
    <>
      <div className="min-vh-100" style={{ backgroundColor: "#F6F4F2" }}>
        <BackToTop />
        <Navbar />
        <br />
        <br />
        <br />

        <Hero
          title="Filtering, Classification, and Categorization of 2D Data"
          subTitle=""
          img={about_background}
          showAnimationText="false"
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/a-computer-generated-image-of-a-pink-and-purple-wave-7FqOISWr5V0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />

        <br />
        <div
          className="container"
          style={{ paddingBlock: "1em", marginTop: "1em" }}
        >
          <Breadcrumbs />
          <div className="row">
            <div className="container content-font-size">
              Our research initiatives encompass the development of advanced
              methodologies for the categorization of images. We focus on
              creating algorithms and models that can efficiently classify
              images into distinct categories or classes based on their content,
              characteristics, and features. This process involves the
              extraction of discriminative features and the utilization of
              machine learning and deep learning techniques to enable precise
              image categorization. The ability to categorize images is
              fundamental in a wide array of applications, including image
              retrieval, content organization, and object recognition in
              computer vision. By refining these categorization algorithms, we
              strive to provide enhanced capabilities for automatically
              organizing and understanding visual data, ultimately contributing
              to more efficient and informed decision-making processes in
              various fields, such as categorization towards 3D reconstruction,
              e-commerce, healthcare, and multimedia content management.
            </div>
          </div>
          <br />
          <div className="col text-center">
            <img src={img1} className="img-fluid" alt="..." />
          </div>
          <br />
          <div className="row">
            <div className="container content-font-size">
              Our research endeavors extend to the development of sophisticated
              techniques for filtering distorted images. This involves the
              creation of algorithms and models that can effectively mitigate
              various forms of distortions in visual data. These distortions may
              encompass noise, blurriness, and other imperfections that can
              compromise image quality. The application of such filtering
              methods is vital across numerous domains, including medical
              imaging, satellite imagery processing, 3D reconstruction, and
              content restoration. By designing and implementing robust
              filtering algorithms, we aim to enhance the quality and clarity of
              distorted images, making them more reliable and informative for
              downstream analysis and decision-making processes.
            </div>
          </div>
          <br />
          <div className="col text-center">
            <img src={img2} className="img-fluid" alt="..." />
          </div>
          <br />
        </div>
        {/* related publications code */}
        <br />
        <div
          className="text-center fw-bold heading-font-size"
          style={{
            paddingBlockEnd: "1em",
          }}
        >
          Related Publications
        </div>
        <PublicationsFetch researchFilter="Filtering Classification and Categorization of Data" />
        {/* related publications code */}
        <Footer />
      </div>
    </>
  );
}

export default TwoDimensionalPage4;
