import React from "react";
import Navbar from "../Components/Common/Navbar";
import bgimg from "../Images/topbg.webp";
import Hero from "../Components/Common/Hero.js";

export default function ErrorFetch() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />

      <Hero title="" subTitle="" img={bgimg} showAnimationText="false" 
      creatorLink="https://unsplash.com/@maxberg?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
      creator="Maxim Berg"
      imageLink="https://unsplash.com/photos/a-long-pink-and-purple-wave-on-a-white-background-OKjxoWaKNI0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
      source="Unsplash"/>
      <br />
      <div
      // style={{
      //   backgroundImage: `url(${bgimg})`,
      //   height: "100vh",
      //   backgroundSize: "cover",
      // }}
      >
        <div
          className="container d-flex justify-content-center text-center mt-5"
          style={{ flexDirection: "column" }}
        >
          <h1 className="fw-semibold" style={{ fontSize: "6rem" }}>
            404! Not Found
          </h1>
          {/* <h1 style={{fontSize:"2rem"}}>You Seem to Have Encountered an Issue</h1> */}
          <h1 className="mt-3" style={{ fontSize: "2rem" }}>
            You can go back to{" "}
            <span>
              <a href="/" style={{ color: "black" }}>
                Home-Page
              </a>
            </span>
          </h1>
          {/* <a className="mt-4 sub-heading-font-size" style={{ color: "black"}} href="mailto:seed-support@cevi.co.in">Contact the devs and let them know</a> */}
        </div>
      </div>
    </div>
  );
}
