import React from "react";
import hero from "../Images/2D/Hero.webp";
import card1 from "../Images/3D/green_room.webp";
import card2 from "../Images/3D/card2.webp";
import card3 from "../Images/3D/card3.webp";
import card7 from "../Images/3D/AICTE.webp";
import card5 from "../Images/3D/Interaction.webp";
import card6 from "../Images/3D/card6.webp";

import Cards from "../Components/Research/Cards_main.js";
import Navbar from "../Components/Common/Navbar";
import BackToTop from "../Components/Common/BackToTop";
import Breadcrumbs from "../Components/Common/Breadcrumbs";

import Footer from "../Pages/Footer.js";
import Hero from "../Components/Common/Hero.js";

import ReadMore from "../Components/Common/ReadMore.js";

function ThreeDimensional() {
  return (
    <>
      {" "}
      <div className="main pages_background  min-vh-100 w-100">
        <BackToTop />
        <Navbar />

        <br />
        <br />
        <br />

        <Hero
          title="3D Data Acquisition and Processing"
          subTitle=""
          img={hero}
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/a-computer-generated-image-of-a-pink-and-purple-wave-7FqOISWr5V0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />

        <div className="">
          <div
            className="container"
            style={{ paddingBlock: "1em", marginTop: "1em" }}
          >
            <Breadcrumbs />

            <div className="content-font-size" style={{ textAlign: "justify" }}>
              <ReadMore>
                In recent years, dealing with the huge diversity and complexity
                of 3D data has become the main research challenge for various
                applications in computer vision, graphics, robotics, Meta-Verse,
                archaeology, agriculture, forestry, AR/VR/XR/MR, autonomous
                vehicles, Industry 4.0, etc. One key approach that researchers
                have found promising is to decompose the complex 3D data into
                smaller and easier composable subcomponents. For example, this
                could be decomposition into basic shapes to understand the
                geometry/surface, or this could be a decomposition of an object
                into spatially localized parts and a sparse set of relationships
                between them, or in scenes, it could be a scene graph, where
                rich inter-object relationships are described, or in robotics
                decomposition into separate parts of concepts or submodules that
                are related by spatial, causal, or semantic relationships. Our
                current research focus is on 3D data acquisition and processing
                is dedicated to enhancing machines' ability to perceive,
                analyze, and interact with the three-dimensional world. We focus
                on improving data quality, extracting meaningful features, and
                developing algorithms across a spectrum of applications.
                Ultimately, our aim is to empower machines with a deeper
                understanding of the 3D visual data they encounter, opening
                doors to innovative and impactful applications in various
                fields.
              </ReadMore>

              {/* <p>
                Explore our website to learn more about our research projects,
                publications, and collaborations. Contact us today to discuss
                potential partnerships, research opportunities, or to explore
                how our expertise can benefit your specific needs. Together,
                let's unlock the power of 3D data acquisition and processing and
                drive innovation in your domain.
              </p> */}
            </div>
          </div>
        </div>

        <div style={{ backgroundColor: "", paddingBlock: "1em" }}>
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-4 col-md-6 gx-md-4 gx-xxl-4 gx-0 justify-content-center">
                <Cards
                  id="01"
                  img={card1}
                  title="3D Data Acquisition"
                  link="/Research/3d/Three_Dimensional_Page1"
                  // desc="Our research begins with the development of advanced 3D data capture techniques and technologies. This involves selecting or designing appropriate sensors, LiDAR systems, or depth cameras to acquire accurate and comprehensive 3D data."
                />
              </div>

              <div className="col-lg-4 col-md-6 gx-md-4 gx-xxl-4 gx-0 justify-content-center">
                <Cards
                  id="02"
                  img={card3}
                  title="3D Reconstruction and Recreation"
                  link="/Research/3d/Three_Dimensional_Page2"
                  // desc="Reconstructing and recreating 3D scenes or objects from acquired data. This process involves creating detailed 3D models from point clouds or depth maps, enabling a better representation of the real-world environment."
                />
              </div>

              <div className="col-lg-4 col-md-6 gx-md-4 gx-xxl-4 gx-0 justify-content-center">
                <Cards
                  id="03"
                  img={card2}
                  title="Learning Representation of 3D Data"
                  link="/Research/3d/Three_Dimensional_Page3"
                  // desc="Focus on devising methods for representing 3D data in formats suitable for machine learning and deep learning models. This includes generating feature vectors or embeddings that encapsulate critical information about 3D objects or scenes."
                />
              </div>

              <div className="col-lg-4 col-md-6 gx-md-4 gx-xxl-4 gx-0 justify-content-center">
                <Cards
                  id="04"
                  img={card5}
                  title="3D Object Detection and Tracking"
                  link="/Research/3d/Three_Dimensional_Page6"
                  // desc="Development of algorithms and models for the accurate detection and tracking of objects within three-dimensional spaces. These capabilities are vital for applications like autonomous vehicles, robotics, and augmented reality."
                />
              </div>

              <div className="col-lg-4 col-md-6 gx-md-4 gx-xxl-4 gx-0 justify-content-center">
                <Cards
                  id="05"
                  img={card6}
                  title="Refinement of 3D Data"
                  link="/Research/3d/Three_Dimensional_Page4"
                  // desc="Develop techniques for enhancing and preprocessing 3D data to improve its quality. Techniques such as denoising, calibration, and alignment are applied to eliminate errors and inconsistencies in the data."
                />
              </div>

              <div className="col-lg-4 col-md-6 gx-md-4 gx-xxl-4 gx-0 justify-content-center">
                <Cards
                  id="06"
                  img={card7}
                  title="3D Data Filtering, Classification, and Categorization"
                  link="/Research/3d/Three_Dimensional_Page5"
                  // desc="Employ filtering, classification, and categorization techniques to organize and classify 3D data based on object attributes, shapes, or other relevant characteristics. This categorization is essential for tasks such as object recognition and scene understanding."
                />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default ThreeDimensional;
