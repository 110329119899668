import React from "react";
import "../../Stylesheets/Research_card.css";
import { Link } from "react-router-dom";

function Cards(props) {
  return (
    <>
      <div className="col card h-70 research-cards rounded-4 border-0 proj-card-body">
        <Link
          to={props.link}
          className="stretched-link text-black text-decoration-none"
        >
          <div className="row-6 card-body px-4 py-3"
          >
            <div
              
              className="row-2 card-title fade-effect-title align-content-center sub-heading-font-size proj-card-link"
              style={{ height: "82px" }}
            >
              {props.title}
            </div>
            <div className="row-2">
              <p className="card-text content-font-size proj-card-desc">{props.desc}</p>
            </div>
          </div>
          <img
            src={props.img}
            className="row-5 bg-white card-img-bottom img-fluid rounded-top-0 rounded-4  h-150 proj-card-img"
            alt="..."
          />
        </Link>
      </div>
    </>
  );
}

export default Cards;
